import Axios from 'axios';
import { getLanguage } from '../i18n';
import cookieAPI from './cookieAPI';
import { _TOKEN_KEY, _CUSTOMERID_KEY } from '../redux/userSlice';
import { getBaseUrl } from './baseUrl';
import store from '../redux/store';
import { logoutAsync } from '../redux/userSlice';

// Add a request interceptor
Axios.interceptors.request.use(
    async (config) => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept-Language'] = getLanguage();
        const token = cookieAPI.get(_TOKEN_KEY);
        const customerId = cookieAPI.get(_CUSTOMERID_KEY);
        const hasBasicAuthHeader =
            config.headers['Authorization'] && config.headers['Authorization'].includes('Basic ');
        if (token && !hasBasicAuthHeader) config.headers.Authorization = `Bearer ${token}`;
        if (customerId) config.headers['Active-Customer-Id'] = customerId;
        return config;
    },
    (error) => {
        // Do something with request error
        return Promise.reject(error);
    },
);

// Add a response interceptor
Axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (typeof response.data !== 'object') {
            throw new Error('API response is not of type object');
        }

        // is no success (because of an access denied error for example)
        if (response.data.noAccess) {
            store.dispatch(logoutAsync());
            throw new Error('API access denied!');
        }
        return response;
    },
    async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            console.error('INVALID TOKEN');
            // originalRequest._retry = true;
            // // const access_token = await refreshAccessToken();
            // const access_token = 'NEW TOKEN';
            // Axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
            // return axiosApiInstance(originalRequest);
        }
        return Promise.reject(error);
    },
);

const API = {
    async post({ endpoint = '', headers = {}, params = {}, data = {}, type = 'json', axiosOptions = {} }) {
        return await this.doAjaxCall(endpoint, 'post', headers, params, data, type, axiosOptions);
    },
    async get({ endpoint = '', headers = {}, params = {}, data = {}, type = 'json', axiosOptions = {} }) {
        return await this.doAjaxCall(endpoint, 'get', headers, params, data, type, axiosOptions);
    },
    async doAjaxCall(
        endpoint = '',
        method = 'post',
        headers = {},
        params = {},
        data = {},
        type = 'json',
        axiosOptions = {},
    ) {
        const fullURL = `/${endpoint}/`;

        // populate the dataToPost variable
        let dataToPost = JSON.stringify(data);
        if (type === 'form') {
            dataToPost = new FormData();
            Object.keys(data).forEach((key) => {
                const dataFromKey = data[key];
                if (key === 'file') {
                    dataToPost.append('file', {
                        uri: dataFromKey.uri,
                        name: dataFromKey.name,
                        type: dataFromKey.type,
                    });
                } else {
                    dataToPost.append(key, dataFromKey);
                }
            });
        }
        if (method === 'get') {
            return await Axios({
                withCredentials: true,
                baseURL: getBaseUrl(),
                url: fullURL,
                headers: headers,
                params: params,
                method: method,
                ...{ axiosOptions },
            });
        }
        return await Axios({
            withCredentials: true,
            baseURL: getBaseUrl(),
            url: fullURL,
            headers: headers,
            params: params,
            method: method,
            data: dataToPost,
            ...{ axiosOptions },
        });
    },
};
export default API;
