import PropTypes from 'prop-types';
import Icon from '../../Atoms/Icon/Icon';

import './Modal.scss';

const _CLASSNAME = 'c_layout-modal';

const Modal = ({ children, closeModalCustom }) => {
    return (
        <div className={`${_CLASSNAME}`}>
            <div className={`${_CLASSNAME}__inner`}>{children}</div>
            <span className={`${_CLASSNAME}__close`} onClick={closeModalCustom} role="button">
                <Icon icon="close" className={`${_CLASSNAME}__close-icon`} size={1.4} />
            </span>
        </div>
    );
};

export default Modal;

Modal.propTypes = {
    children: PropTypes.node,
    closeModalCustom: PropTypes.func.isRequired,
};
Modal.defaultProps = {};
