import PropTypes from 'prop-types';
import { Fragment } from 'react';

import Card from '../../Molecules/Card/Card';

import './CardGroup.scss';

const _CLASSNAME = 'c_card-group';

const CardGroup = ({ children, className, cards, cardClassName, cardType, cardIconColor }) => {
    return (
        <div className={[_CLASSNAME, className].filter((x) => x !== '').join(' ')}>
            {cards.map((card, i) => (
                <Fragment key={`cardgroup-card-${i}-${card.id || ''}`}>
                    <Card
                        iconColor={cardIconColor}
                        type={cardType}
                        {...card}
                        className={[`${card.className}`, cardClassName].filter((x) => x !== '').join(' ')}
                        grouped
                    />
                    {i < cards.length && <div className={`${_CLASSNAME}__spacer`} />}
                </Fragment>
            ))}
            {children}
        </div>
    );
};

export default CardGroup;

CardGroup.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    cards: PropTypes.array.isRequired,
    cardClassName: PropTypes.string,
    cardType: PropTypes.string,
    cardIconColor: PropTypes.string,
};
CardGroup.defaultProps = {
    className: '',
    cards: [],
    cardClassName: '',
    cardType: 'cart',
    cardIconColor: 'secondary',
};
