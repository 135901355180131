import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Card from '../../components/Molecules/Card/Card';
import LanguageSwitch from '../../components/Molecules/LanguageSwitch/LanguageSwitch';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import { logoutAsync } from '../../redux/userSlice';

import './Profile.scss';
const _CLASSNAME = 'c_profile';
const _IMG = 'profile.png';

const Item = ({ label, value }) => (
    <p className={`${_CLASSNAME}__item`}>
        <span className="secondary">{label}</span>
        <br />
        <span dangerouslySetInnerHTML={{ __html: value }} />
    </p>
);

const _USERDATAKEYSTOSHOW = [
    'name',
    'companyName',
    'accountNumber',
    'accountManager',
    'incoterms',
    'payment',
    'address',
    'phone',
    'email',
];

const Profile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const { t } = useTranslation();

    const handleLogout = async () => {
        dispatch(logoutAsync()).then(() => {
            history.push('/login');
        });
    };

    return (
        <DefaultLayout img={_IMG}>
            <Card>
                <Wysiwyg>
                    <Title title={t('containers.profile.title')} className={`${_CLASSNAME}__title`} />
                    <button onClick={handleLogout} className={`${_CLASSNAME}__logout`}>
                        {t('general.logout')}
                    </button>
                    <div className={`${_CLASSNAME}__lang mb-5`}>
                        <p className="mb-2">{t('containers.profile.language')}</p>
                        <LanguageSwitch type="select" />
                    </div>
                    <Title title={t('containers.profile.details')} className={`${_CLASSNAME}__title`} />
                    {_USERDATAKEYSTOSHOW.map((key, index) => {
                        if (user.user[key])
                            return (
                                <Item
                                    key={`user-data-key-${key}-${index}`}
                                    label={t(`containers.profile.${key.toLowerCase()}`)}
                                    value={user.user[key]}
                                />
                            );
                        return null;
                    })}
                    <p className={`${_CLASSNAME}__infotext`}>
                        <i>{t('containers.profile.infotext')}</i>
                    </p>
                </Wysiwyg>
                <Preloader includeOverlay={true} active={user.isLoading} />
            </Card>
        </DefaultLayout>
    );
};

export default Profile;
