import Login from '../containers/Login/Login';
import Password from '../containers/Password/Password';
import DashBoard from '../containers/DashBoard/DashBoard';
import Profile from '../containers/Profile/Profile';
import Orders from '../containers/Orders/Orders';
import OrderDetail from '../containers/OrderDetail/OrderDetail';
import Products from '../containers/Products/Products';
import ProductDetail from '../containers/ProductDetail/ProductDetail';
import Cart from '../containers/Cart/Cart';
import CartDetail from '../containers/CartDetail/CartDetail';
import CartCheckout from '../containers/CartCheckout/CartCheckout';
import CartSuccess from '../containers/CartSuccess/CartSuccess';

// these are the actual PAGES. For the MODAL view routes see below!
const pages = [
    {
        path: ['/login'],
        component: Login,
        exact: true,
        protected: false,
    },
    {
        path: ['/password/:hash'],
        component: Password,
        exact: true,
        protected: false,
    },
    {
        path: ['/', '/dashboard/'],
        component: DashBoard,
        exact: true,
        protected: true,
    },
    {
        path: '/profile/',
        component: Profile,
        exact: true,
        protected: true,
    },
    {
        path: '/products/',
        component: Products,
        exact: false,
        protected: true,
    },
    {
        path: '/orders/',
        component: Orders,
        exact: false,
        protected: true,
    },
    {
        path: '/cart/',
        component: Cart,
        exact: false,
        protected: true,
    },
    {
        path: '/success/:simpleId/',
        component: CartSuccess,
        exact: true,
        protected: true,
    },
];

// These are the modal routes!
// NOTE: if modal "path" prop is an array, make sure the "parentPath" prop is also an array of paths.
// This is used to determine the parent path when a modal is closed. So make sure this stuff is correct!
const modals = [
    {
        path: ['/products/:simpleId/'],
        parentPath: ['/products/'],
        component: ProductDetail,
        exact: true,
        protected: true,
    },
    {
        path: '/cart/checkout/',
        parentPath: '/cart/',
        component: CartCheckout,
        exact: true,
        protected: true,
    },
    {
        path: '/cart/edit/:id/',
        parentPath: '/cart/',
        component: CartDetail,
        exact: true,
        protected: true,
    },
    {
        path: '/orders/:simpleId/',
        parentPath: '/orders/',
        component: OrderDetail,
        exact: true,
        protected: true,
    },
];

const pagesAndModalRoutes = {
    pages,
    modals,
};

export default pagesAndModalRoutes;
