import PropTypes from 'prop-types';

import './Footer.scss';

const _CLASSNAME = 'c_footer';

const Footer = ({ className, children }) => {
    return (
        <footer className={[_CLASSNAME, className].filter((x) => x !== '').join(' ')}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className={`${_CLASSNAME}__inner`}>{children}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

Footer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};
Footer.defaultProps = {
    className: '',
};
