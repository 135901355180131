import PropTypes from 'prop-types';

import Wysiwyg from '../../Atoms/Wysiwyg/Wysiwyg';
import Card from '../../Molecules/Card/Card';
import Title from '../../Atoms/Title/Title';

const _CLASSNAME = 'c_no-result-card';

const NoResultCard = ({ title, text, className }) => {
    return (
        <Card className={[_CLASSNAME, className].filter((i) => i !== '').join(' ')}>
            {title !== '' && <Title title={title} className="mb-3" />}
            {text !== '' && (
                <Wysiwyg>
                    <p>{text}</p>
                </Wysiwyg>
            )}
        </Card>
    );
};
export default NoResultCard;

NoResultCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
};
NoResultCard.defaultProps = {
    title: '',
    text: '',
    className: '',
};
