import PropTypes from 'prop-types';
import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'clone-deep';

import { isNumber, sumSiloWeight, calculateWeights, _LOWEST_PALLET_VALUE } from '../../../utilities/unitconversions';

import Button from '../../Atoms/Button/Button';
import Input from '../../Atoms/Input/Input';

import './OrderForm.scss';

const _CLASSNAME = 'c_order-form';

const OrderForm = ({
    className,
    type,
    isNew,
    productId,
    kg,
    bags,
    bigbags,
    pieces,
    pallets,
    silos,
    weights,
    remark,
    isLoading,
    onInvalid,
    onValid,
    beforeSubmit,
    isCustomProduct,
    productName,
    productCode,
}) => {
    const { t } = useTranslation();

    // editable variables
    const [_errors, _setErrors] = useState([]);
    const [_kg, _setKg] = useState(kg);
    const [_bags, _setBags] = useState(bags);
    const [_bigbags, _setBigbags] = useState(bigbags);
    const [_pieces, _setPieces] = useState(pieces);

    const [_pallets, _setPallets] = useState(pallets);
    const [_silos, _setSilos] = useState(silos.length ? silos : [{ weight: 0, label: '' }]);
    const [_totalSiloWeight, _setTotalSiloWeight] = useState(silos.length ? sumSiloWeight(silos) : 0);
    const [_remark, _setRemark] = useState(remark);

    // data for custom product
    const [_productName, _setProductName] = useState(productName);
    const [_productCode, _setProductCode] = useState(productCode);

    // function to bundle all the inputted data
    const bundleData = () => {
        const data = {
            productId,
            quantity: {
                kg: _kg || 0,
                bags: _bags || 0,
                bigbags: _bigbags || 0,
                pieces: _pieces || 0,
                pallets: _pallets || 0,
                silos: _silos,
            },
            remark: _remark,
        };
        // add the extra fields to the data
        if (isCustomProduct) {
            data['productName'] = _productName;
            data['productCode'] = _productCode;
        }
        return data;
    };

    const handleSubmit = async () => {
        if (beforeSubmit) {
            const res = beforeSubmit();
            if (res === false) return false;
        }

        // Do some validation
        // ---------------------------------------------
        let errors = [];
        if (type === 'bulk') {
            _silos.forEach((silo, index) => {
                if (silo.weight <= 0) errors.push(`silo_weight_${index}`);
                if (silo.label === '') errors.push(`silo_label_${index}`);
            });
        } else {
            if (
                (!isNumber(_kg) || _kg <= 0) &&
                (!isNumber(_bags) || _bags <= 0) &&
                (!isNumber(_pieces) || _pieces <= 0) &&
                (!isNumber(_pallets) || _pallets <= 0) &&
                (!isNumber(_bigbags) || _bigbags <= 0)
            ) {
                errors = ['kg', 'bags', 'pallets', 'pieces', 'bigbags'];
            }
        }

        // validate the customproduct values before submit
        if (isCustomProduct && _productName === '') errors.push('productName');

        _setErrors(errors);
        if (errors.length) {
            if (onInvalid) onInvalid(errors, bundleData());
            return false;
        }

        // everything seems valid
        if (onValid) onValid(bundleData());
        return true;
    };

    const doCalculations = (e) => {
        const name = e.target.name;
        const value = parseInt(e.target.value);
        const funcs = {
            kg: _setKg,
            bags: _setBags,
            bigbags: _setBigbags,
            pallets: _setPallets,
            pieces: _setPieces,
        };
        if (isCustomProduct) {
            funcs[name](value);
        } else {
            const convertedValues = calculateWeights(type, name, value, weights);
            Object.keys(convertedValues).forEach((key) => {
                funcs[key](convertedValues[key]);
            });
        }
    };

    const updateSiloValue = (type, index, value) => {
        const newState = cloneDeep(_silos);
        newState[index][type] = value;
        _setSilos(newState);
        _setTotalSiloWeight(sumSiloWeight(newState));
    };
    const removeSilo = (index) => {
        _setSilos(cloneDeep(_silos).filter((silo, i) => i !== index));
    };
    const addEmptySilo = () => {
        const newState = [
            ..._silos,
            ...[
                {
                    weight: 0,
                    label: '',
                },
            ],
        ];
        _setSilos(newState);
        _setTotalSiloWeight(sumSiloWeight(_silos));
    };
    return (
        <div className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}>
            {
                // Extra fields for the custom product
                isCustomProduct && (
                    <div className="mt-4 mb-4">
                        <Input
                            name="productName"
                            id="productName"
                            value={_productName}
                            onChange={(e) => {
                                _setProductName(e.target.value);
                            }}
                            label={t('order.productname')}
                            error={_errors.includes('productName')}
                            className="mb-3"
                        />
                        <Input
                            name="productCode"
                            id="productCode"
                            value={_productCode}
                            onChange={(e) => {
                                _setProductCode(e.target.value);
                            }}
                            label={t('order.productcode')}
                        />
                    </div>
                )
            }
            {type === 'bulk' ? (
                <Fragment>
                    {_silos.map((silo, index) => (
                        <div className={`${_CLASSNAME}__silo`} key={`silo-block-${index}`}>
                            <Input
                                type="tel"
                                name={`silo_weight_${index}`}
                                id={`silo_weight_${index}`}
                                value={silo.weight === 0 ? undefined : silo.weight}
                                onChange={(e) => {
                                    updateSiloValue('weight', index, parseInt(e.target.value) || 0);
                                }}
                                label={t('order.units.kg')}
                                className={`${_CLASSNAME}__input`}
                                error={_errors.includes(`silo_weight_${index}`)}
                            />
                            <Input
                                type="text"
                                name={`silo_label_${index}`}
                                id={`silo_label_${index}`}
                                value={silo.label}
                                onChange={(e) => {
                                    updateSiloValue('label', index, e.target.value);
                                }}
                                label={t('order.units.bulk')}
                                className={`${_CLASSNAME}__input`}
                                error={_errors.includes(`silo_label_${index}`)}
                            />
                            {_silos.length > 1 && (
                                <Button
                                    label={t('order.remove-silo')}
                                    inline={true}
                                    icon="delete"
                                    onClick={() => {
                                        removeSilo(index);
                                    }}
                                    size="small"
                                />
                            )}
                            {_silos.length > 1 && index < _silos.length - 1 && (
                                <hr className={`${_CLASSNAME}__silo-spacer`} />
                            )}
                        </div>
                    ))}
                    <hr className={`${_CLASSNAME}__silo-spacer`} />
                    <div className={`${_CLASSNAME}__silo-actions`}>
                        <p className="mb-3">
                            <i>
                                {t('order.total')} {_totalSiloWeight}
                            </i>
                        </p>
                        <Button
                            label={t('order.add-silo')}
                            inline={true}
                            size="small"
                            icon="add"
                            onClick={addEmptySilo}
                        />
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <Input
                        type="tel"
                        name="kg"
                        id="kg"
                        value={_kg === 0 ? undefined : _kg}
                        onChange={doCalculations}
                        label={t('order.units.kg')}
                        className={`${_CLASSNAME}__input`}
                        error={_errors.includes('kg')}
                    />
                    {type === 'piece' && (
                        <Input
                            type="tel"
                            name="pieces"
                            id="pieces"
                            value={_pieces === 0 ? undefined : _pieces}
                            onChange={doCalculations}
                            label={t('order.units.pieces')}
                            className={`${_CLASSNAME}__input`}
                            error={_errors.includes('pieces')}
                            spinners={true}
                            onMin={() => {
                                if (_pieces && _pieces >= 1 && !isNaN(_pieces))
                                    doCalculations({ target: { name: 'pieces', value: _pieces - 1 } });
                            }}
                            onPlus={() => {
                                const value = _pieces ? _pieces + 1 : 1;
                                doCalculations({ target: { name: 'pieces', value } });
                            }}
                        />
                    )}
                    {type === 'bigbag' && (
                        <Input
                            type="tel"
                            name="bigbags"
                            id="bigbags"
                            value={_bigbags === 0 ? undefined : _bigbags}
                            onChange={doCalculations}
                            label={t(`order.units.bigbag`)}
                            className={`${_CLASSNAME}__input`}
                            error={_errors.includes('bigbags')}
                            spinners={true}
                            onMin={() => {
                                if (_bigbags && _bigbags >= 1 && !isNaN(_bigbags))
                                    doCalculations({ target: { name: 'bigbags', value: _bigbags - 1 } });
                            }}
                            onPlus={() => {
                                const value = _bigbags ? _bigbags + 1 : 1;
                                doCalculations({ target: { name: 'bigbags', value } });
                            }}
                        />
                    )}
                    {type === 'bag' && (
                        <Fragment>
                            <Input
                                type="tel"
                                name="bags"
                                id="bags"
                                value={_bags === 0 ? undefined : _bags}
                                onChange={doCalculations}
                                label={t(`order.units.bags`)}
                                className={`${_CLASSNAME}__input`}
                                error={_errors.includes('bags')}
                                onMin={() => {
                                    if (_bags && _bags >= 1 && !isNaN(_bags))
                                        doCalculations({ target: { name: 'bags', value: _bags - 1 } });
                                }}
                                onPlus={() => {
                                    const value = _bags ? _bags + 1 : 1;
                                    doCalculations({ target: { name: 'bags', value } });
                                }}
                            />
                            <Input
                                type="tel"
                                name="pallets"
                                id="pallets"
                                value={_pallets === 0 ? undefined : _pallets}
                                onChange={doCalculations}
                                label={t('order.units.pallets')}
                                className={`${_CLASSNAME}__input`}
                                error={_errors.includes('pallets')}
                                spinners={true}
                                onMin={() => {
                                    if (_pallets && _pallets >= _LOWEST_PALLET_VALUE && !isNaN(_pallets))
                                        doCalculations({ target: { name: 'pallets', value: _pallets - 1 } });
                                }}
                                onPlus={() => {
                                    const value = _pallets ? _pallets + 1 : 1;
                                    doCalculations({ target: { name: 'pallets', value } });
                                }}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}

            <Input
                type="textarea"
                name="comment"
                id="comment"
                value={_remark}
                onChange={(e) => {
                    _setRemark(e.target.value);
                }}
                label={t('order.remarks')}
                className={`${_CLASSNAME}__input`}
                error={_errors.includes('remark')}
            />
            <div className={`d-flex justify-content-center ${_CLASSNAME}__submit-wrapper`}>
                <Button
                    label={isNew ? t('order.add-to-cart') : t('order.update-cart')}
                    variant="secondary"
                    disabled={isLoading}
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default OrderForm;

OrderForm.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['piece', 'bag', 'bigbag', 'bulk']).isRequired,
    isNew: PropTypes.bool,
    productId: PropTypes.string,
    kg: PropTypes.number,
    bags: PropTypes.number,
    bigbags: PropTypes.number,
    pieces: PropTypes.number,
    pallets: PropTypes.number,
    silos: PropTypes.array,
    weights: PropTypes.object.isRequired,
    remark: PropTypes.string,
    isLoading: PropTypes.bool,
    onInvalid: PropTypes.func,
    onValid: PropTypes.func,
    beforeSubmit: PropTypes.func,
    isCustomProduct: PropTypes.bool,
    productName: PropTypes.string,
    productCode: PropTypes.string,
};
OrderForm.defaultProps = {
    className: '',
    type: 'bag',
    isNew: true,
    productId: '',
    kg: 0,
    bags: 0,
    bigbags: 0,
    pieces: 0,
    pallets: 0,
    silos: [],
    weights: {},
    remark: '',
    isLoading: false,
    isCustomProduct: false,
    productName: '',
    productCode: '',
};
