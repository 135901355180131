import PropTypes from 'prop-types';

import './Home.scss';

const _CLASSNAME = 'c_layout-home';

const HomeLayout = ({ children, className, img1, img2, contactBlock }) => {
    return (
        <div className={[_CLASSNAME, className].filter((x) => x !== '').join(' ')}>
            <div className="container">
                <div className="row">
                    <div className={`col-12 col-md-6 ${_CLASSNAME}__col ${_CLASSNAME}__col-1`}>
                        <div className={`${_CLASSNAME}__content`}>{children}</div>
                        <div
                            className={`${_CLASSNAME}__img ${_CLASSNAME}__img--1`}
                            style={
                                img1 !== ''
                                    ? {
                                          backgroundImage: `url("${process.env.PUBLIC_URL}/images/layout-images/${img1}")`,
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={`col-12 col-md-6 ${_CLASSNAME}__col ${_CLASSNAME}__col-2`}>
                        <div
                            className={`${_CLASSNAME}__img ${_CLASSNAME}__img--2`}
                            style={
                                img2 !== ''
                                    ? {
                                          backgroundImage: `url("${process.env.PUBLIC_URL}/images/layout-images/${img2}")`,
                                      }
                                    : {}
                            }
                        />
                        {contactBlock && <div className={`${_CLASSNAME}__contact`}>{contactBlock}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeLayout;

HomeLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    contactBlock: PropTypes.node,
    img1: PropTypes.string,
    img2: PropTypes.string,
};
HomeLayout.defaultProps = {
    className: '',
    img1: '',
    img2: '',
};
