import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './OrderOverview.scss';

import Icon from '../../Atoms/Icon/Icon';
import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Wysiwyg from '../../Atoms/Wysiwyg/Wysiwyg';
import CardGroup from '../../Organisms/CardGroup/CardGroup';
import { groupCartItems } from '../../../utilities/products';
import { sumTotalCartWeight } from '../../../utilities/unitconversions';

const _CLASSNAME = 'c_order-overview';

const OrderOverview = ({
    className,
    isSuccessOverview,
    title,
    subTitle,
    text,
    delivery,
    deliveryType,
    address,
    remark,
    cart,
}) => {
    const { t } = useTranslation();
    return (
        <div className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}>
            {isSuccessOverview && <Icon className={`${_CLASSNAME}__icon`} icon="check" size={1.4} />}
            {title !== '' && (
                <Title
                    color={isSuccessOverview ? 'primary' : 'secondary'}
                    html={isSuccessOverview ? title : ''}
                    style={{ paddingRight: 30 }}
                >
                    {title}
                </Title>
            )}
            {subTitle !== '' && (
                <Title color="primary" className="mb-3" style={{ paddingRight: 30 }}>
                    {subTitle}
                </Title>
            )}
            {text !== '' && <Wysiwyg className="mt-3 mb-3">{text}</Wysiwyg>}
            {isSuccessOverview && (
                <div className="d-flex justify-content-center">
                    <Button label={t('order.start-new-order')} to="/products/" className="mb-4" />
                </div>
            )}

            <Wysiwyg className="mb-3">
                {delivery && delivery !== '' && (
                    <p>
                        <span className="secondary">{t(`order.deliverydate-${deliveryType}`)}</span>
                        <br />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: delivery.toString().replace('{{week}}', t('order.week')),
                            }}
                        />
                    </p>
                )}
                {address !== '' && (
                    <p>
                        <span className="secondary">{t('order.address')}</span>
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: address }} />
                    </p>
                )}

                {remark !== '' && (
                    <p>
                        <span className="secondary">{t('order.remarks')}</span>
                        <br />
                        <span dangerouslySetInnerHTML={{ __html: remark }} />
                    </p>
                )}
                <p>
                    <span className="secondary">{t('order.products')}</span>
                </p>
            </Wysiwyg>
            <div className={`${_CLASSNAME}__products ${isSuccessOverview ? `${_CLASSNAME}__products--success` : ''}`}>
                {groupCartItems(cart, false).map((cartItem, index) => (
                    <CardGroup key={`cartItem-${index}`} className={`${_CLASSNAME}__item`} cards={cartItem.items} />
                ))}
            </div>
            {cart.length > 1 && (
                <Fragment>
                    <hr />
                    <p className={`${_CLASSNAME}__total`}>
                        <i>
                            {t('order.total')} {sumTotalCartWeight(cart)}
                        </i>
                    </p>
                </Fragment>
            )}
        </div>
    );
};
export default OrderOverview;

OrderOverview.propTypes = {
    className: PropTypes.string,
    isSuccessOverview: PropTypes.bool,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    text: PropTypes.string,
    delivery: PropTypes.string,
    deliveryType: PropTypes.string,
    address: PropTypes.string,
    remark: PropTypes.string,
    cart: PropTypes.array.isRequired,
};
OrderOverview.defaultProps = {
    className: '',
    isSuccessOverview: false,
    title: '',
    subTitle: '',
    text: '',
    delivery: '',
    deliveryType: '',
    address: '',
    remark: '',
    cart: [],
};
