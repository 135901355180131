import PropTypes from 'prop-types';

import './AlertText.scss';

import Icon from '../Icon/Icon';

const _CLASSNAME = 'c_alert-text';

const AlertText = ({ icon, text, color, className }) => {
    return (
        <span
            className={[_CLASSNAME, className, `${_CLASSNAME}--${color}`, 'd-flex align-items-center']
                .filter((c) => c !== '')
                .join(' ')}
        >
            <Icon icon={icon} className="mr-2" />
            <span className={`${_CLASSNAME}__label`}>{text}</span>
        </span>
    );
};

export default AlertText;

AlertText.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.oneOf(['default', 'error', 'info', 'success', 'warning', 'white', 'primary']).isRequired,
    text: PropTypes.string,
};
AlertText.defaultProps = {
    icon: 'error',
    className: '',
    color: 'default',
    text: '',
};
