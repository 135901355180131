// https://hasura.io/blog/best-practices-of-using-jwt-with-graphql/
// https://github.com/vnovick/graphql-jwt-tutorial/blob/master/nextjsapp/utils/auth.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api/api';
import cookieAPI from '../api/cookieAPI';
import base64 from 'base-64';
import i18n, { getSupportedLocales, saveLanguage, languageIsPreviouslySet } from '../i18n';
import store from './store';
import { navigatorOnline } from '../utilities/useOnlineStatus';

const _TOKEN_KEY = 'token';
const _CUSTOMERID_KEY = 'customerid';
const _EXPIREDATE_KEY = 'expiredate';

export { _TOKEN_KEY, _CUSTOMERID_KEY, _EXPIREDATE_KEY };

window.refreshTokenTimer = null;

export const clearRefreshTokenTimer = () => {
    clearInterval(window.refreshTokenTimer);
};

export const startRefreshTokenTimer = () => {
    // 30 second = 30000 milliseconds
    // 1 minute = 60000 milliseconds
    // 5 minutes = 300000 milliseconds
    // 10 minutes = 600000 milliseconds
    // 15 minutes = 900000 milliseconds
    clearRefreshTokenTimer();
    const timeout = process.env.REACT_APP_REFRESH_TOKEN_TIMEOUT
        ? parseInt(process.env.REACT_APP_REFRESH_TOKEN_TIMEOUT)
        : 600000;
    window.refreshTokenTimer = window.setInterval(() => {
        if (navigatorOnline()) store.dispatch(validateTokenAsync());
    }, timeout);
};

export const validateTokenAsync = createAsyncThunk('validateTokenAsync', async () => {
    const res = await API.get({
        endpoint: 'refresh_token',
    });

    if (res.data.success) {
        cookieAPI.save(_TOKEN_KEY, res.data.authToken);
        cookieAPI.save(_EXPIREDATE_KEY, res.data.expiryDate);
    } else {
        cookieAPI.delete(_TOKEN_KEY);
        cookieAPI.delete(_EXPIREDATE_KEY);
        clearRefreshTokenTimer();
    }
    return res.data;
});

export const loginAsync = createAsyncThunk('loginAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'auth',
        data: {
            remember: payload.remember,
        },
        headers: {
            Authorization: `Basic ${base64.encode(`${payload.username}:${payload.password}`)}`,
        },
    });

    // persist the token in the storage!
    if (res.data.success) {
        let customerId = res.data.user.customerId;
        if (res.data.user.role === 'reseller') {
            const filtered = res.data.user.clients.filter((item) => item.active);
            customerId = filtered.length ? filtered[0].id : res.data.user.clients[0].id;
            if (res.data.user.clients.length === 1) {
                res.data.user.clients[0].active = true;
            }
        }
        cookieAPI.save(_TOKEN_KEY, res.data.authToken);
        cookieAPI.save(_CUSTOMERID_KEY, customerId);
        cookieAPI.save(_EXPIREDATE_KEY, res.data.expiryDate);
        startRefreshTokenTimer();

        // update the language if we need to!
        if (!languageIsPreviouslySet()) {
            const supported = getSupportedLocales();
            const userLang = res.data.user.locale;
            const localeToUse = supported.filter(
                (loc) => loc.locale.split('-')[0].toLowerCase() === userLang.toLowerCase(),
            );
            if (localeToUse.length === 1 && localeToUse[0].locale !== i18n.language) {
                i18n.changeLanguage(localeToUse[0].locale);
                saveLanguage(localeToUse[0].locale);
            }
        }
    } else {
        cookieAPI.delete(_TOKEN_KEY);
        cookieAPI.delete(_CUSTOMERID_KEY);
        cookieAPI.delete(_EXPIREDATE_KEY);
        clearRefreshTokenTimer();
    }

    // return data to reducer handler
    return res.data;
});
export const logoutAsync = createAsyncThunk('logoutAsync', async (payload) => {
    await API.post({
        endpoint: 'logout',
        data: payload,
    });

    // also remove the token from storage
    cookieAPI.delete(_TOKEN_KEY);
    cookieAPI.delete(_CUSTOMERID_KEY);
    cookieAPI.delete(_EXPIREDATE_KEY);
    clearRefreshTokenTimer();

    return {
        isLoading: false,
        loggedIn: false,
        user: {},
    };
});

export const forgotPasswordAsync = createAsyncThunk('user/forgotPasswordAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'forgotten',
        data: payload,
    });

    // return data to reducer handler
    return res.data;
});

export const setPasswordAsync = createAsyncThunk('user/setPasswordAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'setpassword',
        data: payload,
    });
    // return data to reducer handler
    return res.data;
});

export const switchClientAsync = createAsyncThunk('switchClientAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'clientSwitch',
        data: {
            id: payload.id,
        },
    });
    cookieAPI.save(_CUSTOMERID_KEY, payload.id);
    return {
        ...res.data,
        ...{ id: payload.id },
    };
});

const initialState = {
    isLoading: false,
    // init the store with "isRefreshingToken": true if there is a cookie with a token, because when the app loads it always starts off with refreshing the token!
    // this prevents the app from rendering the actual app en redirecting to another page. Instead the app shows a loader untill token call is done
    isRefreshingToken: cookieAPI.get(_TOKEN_KEY) ? true : false,
    // default to not logged in. this in turn triggers the refresh token call when the app starts
    loggedIn: false,
    // this is populated after either the login or refresh token call
    user: {},
    // active when a reseller is switching from one client to another
    isSwitchingClient: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        // validateTokenAsync
        [validateTokenAsync.pending]: (state) => {
            state.isRefreshingToken = true;
        },
        [validateTokenAsync.rejected]: (state) => {
            state.isRefreshingToken = false;
            state.loggedIn = false;
            state.user = {};
        },
        [validateTokenAsync.fulfilled]: (state, action) => {
            state.isRefreshingToken = false;
            if (action.payload.success) {
                state.loggedIn = true;
                state.user = action.payload.user;
            } else {
                state.loggedIn = false;
                state.user = {};
            }
        },

        // loginAsync
        [loginAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [loginAsync.rejected]: (state) => {
            state.isLoading = false;
            state.loggedIn = false;
        },
        [loginAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            if (action.payload.success) {
                state.loggedIn = true;
                state.user = action.payload.user;
            } else {
                state.loggedIn = false;
            }
        },

        // forgotPasswordAsync
        [forgotPasswordAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [forgotPasswordAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [forgotPasswordAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
        },

        // setPasswordAsync
        [setPasswordAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [setPasswordAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [setPasswordAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
        },

        // logoutAsync
        [logoutAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [logoutAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [logoutAsync.fulfilled]: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.loggedIn = action.payload.loggedIn;
            state.user = action.payload.user;
        },

        // switchClientAsync
        [switchClientAsync.pending]: (state) => {
            state.isSwitchingClient = true;
        },
        [switchClientAsync.rejected]: (state) => {
            state.isSwitchingClient = false;
        },
        [switchClientAsync.fulfilled]: (state, action) => {
            state.isSwitchingClient = false;
            const newClients = state.user.clients.map((client) => ({
                ...client,
                active: client.id === action.payload.id,
            }));
            state.user.clients = newClients;
        },
    },
});

// export const {} = userSlice.actions;

export default userSlice.reducer;
