import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../../Molecules/Card/Card';

const ContactCard = () => {
    const user = useSelector((state) => state.user.user);
    const { t } = useTranslation();
    const hasUser = user !== false && Object.keys(user).length;
    return (
        <Card
            type="contact"
            className="mt-auto"
            title={t('contact.title')}
            img={
                hasUser && user.accountManagerImage !== ''
                    ? user.accountManagerImage
                    : `${process.env.PUBLIC_URL}/images/contact-images/${t('contact.img')}`
            }
            subTitle={hasUser && user.accountManager !== '' ? user.accountManager : t('contact.subtitle')}
            tel={hasUser && user.accountManagerPhone !== '' ? user.accountManagerPhone : t('contact.phone')}
        />
    );
};

export default ContactCard;
