import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import Icon from '../Icon/Icon';
import AlertText from '../AlertText/AlertText';

import './AutocompleteSelect.scss';

const _CLASSNAME = 'c_autocomplete-select';

const DropdownIndicator = () => <Icon icon="dropdown" size={2} />;
const IndicatorSeparator = () => null;

const AutocompleteSelect = ({
    className,
    id,
    error,
    label,
    options,
    value,
    onChange,
    noOptionsMessage,
    placeholder,
    errorMessage,
    disabled,
}) => {
    return (
        <div className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}>
            <label
                htmlFor={id}
                className={[`${_CLASSNAME}__label`, `${error ? 's_error' : ''}`].filter((c) => c !== '').join(' ')}
            >
                {label}
            </label>
            <div className={`${_CLASSNAME}__input-wrapper`}>
                <Select
                    inputId={id}
                    options={options}
                    value={Object.keys(value).length <= 0 ? null : value}
                    className={`${_CLASSNAME}__container`}
                    classNamePrefix={`${_CLASSNAME}__select`}
                    onChange={onChange}
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    noOptionsMessage={() => noOptionsMessage}
                    placeholder={<Fragment>{placeholder}</Fragment>}
                    isDisabled={disabled}
                />
            </div>
            {error && errorMessage !== '' && (
                <span className={`${_CLASSNAME}__error-msg`}>
                    <AlertText icon="error" text={errorMessage} color={'primary'} />
                </span>
            )}
        </div>
    );
};

export default AutocompleteSelect;

AutocompleteSelect.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.shape({ id: PropTypes.string, value: PropTypes.string }).isRequired,
    onChange: PropTypes.func.isRequired,
    noOptionsMessage: PropTypes.string,
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
};
AutocompleteSelect.defaultProps = {
    className: '',
    label: '',
    id: '',
    error: false,
    noOptionsMessage: '',
    placeholder: '',
    errorMessage: '',
    disabled: false,
};
