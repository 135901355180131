import { useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getOrdersAsync } from '../../redux/ordersSlice';

import DefaultLayout from '../../components/Layouts/Default/Default';
import OrderOverview from '../../components/Organisms/OrderOverview/OrderOverview';
import Card from '../../components/Molecules/Card/Card';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import './CartSuccess.scss';

// const _CLASSNAME = 'c_cart-checkout';
const _IMG = 'cart.png';

const CartSuccess = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams();
    const orders = useSelector((state) => state.orders);
    const order = useSelector((state) => state.orders.orders.find((order) => order.simpleId === params.simpleId));

    // load data
    const getData = useCallback(() => {
        if (orders.orders.length < 1 || !order) dispatch(getOrdersAsync());
    }, [dispatch, order, orders.orders.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    if (orders.isLoading) {
        return (
            <DefaultLayout img={_IMG}>
                <Preloader color="primary" />
            </DefaultLayout>
        );
    }

    if (order) {
        return (
            <DefaultLayout img={_IMG}>
                <Card>
                    <OrderOverview
                        isSuccessOverview={true}
                        title={t('order.success-title')}
                        text={t('order.success-text')}
                        delivery={order.delivery}
                        deliveryType={order.deliveryType}
                        address={order.addressFormatted}
                        remark={order.remark}
                        cart={order.cart}
                    />
                </Card>
            </DefaultLayout>
        );
    }
    return <Redirect to="/orders/" />;
};

export default CartSuccess;

// CartSuccess.propTypes = {};
// CartSuccess.defaultProps = {};
