import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api/api';

export const getOrdersAsync = createAsyncThunk('todos/getOrdersAsync', async () => {
    const res = await API.get({
        endpoint: 'orders',
    });
    return res.data;
});

export const addOrderAsync = createAsyncThunk('addOrderAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'order/add',
        data: payload,
    });
    return res.data;
});

export const getCheckoutFormdataAsync = createAsyncThunk('cart/getCheckoutFormdataAsync', async (payload) => {
    const res = await API.get({
        endpoint: 'order/data',
    });
    return res.data;
});

const initialState = {
    isLoading: false,
    orders: [],
    checkoutData: {},
    tempCheckoutData: {},
};

export const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        saveTempCheckoutData: (state, action) => {
            state.tempCheckoutData = action.payload;
        },
        clearTempCheckoutData: (state) => {
            state.tempCheckoutData = {};
        },
    },
    extraReducers: {
        // getOrdersAsync
        [getOrdersAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getOrdersAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [getOrdersAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.orders = action.payload.orders;
        },

        // addOrderAsync
        [addOrderAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [addOrderAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [addOrderAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.orders = action.payload.orders;
        },

        // getCheckoutFormdataAsync
        [getCheckoutFormdataAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [getCheckoutFormdataAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getCheckoutFormdataAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.checkoutData = action.payload.data;
        },

        // clear the current history if the user switched from client (only used for user role "reselller")
        'switchClientAsync/fulfilled': (state, action) => {
            state.orders = [];
            state.checkoutData = {};
        },
        // when logged out remove orders and checkoutData
        'logoutAsync/fulfilled': (state, action) => {
            state.orders = [];
            state.checkoutData = {};
        },
    },
});

export const { saveTempCheckoutData, clearTempCheckoutData } = orderSlice.actions;

export default orderSlice.reducer;
