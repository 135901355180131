import React, { Suspense } from 'react';

import ReactDOM from 'react-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './i18n';
import './Index.scss';
import App from './App';
import { OnlineStatusProvider } from './utilities/useOnlineStatus';
import LoadingScreen from './containers/LoadingScreen/LoadingScreen';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

// here app catches the suspense from page in case translations are not yet loaded
function WrappedApp() {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <OnlineStatusProvider>
                <Provider store={store}>
                    <Router>
                        <App />
                    </Router>
                </Provider>
            </OnlineStatusProvider>
            <ToastContainer pauseOnHover={false} position={'top-center'} autoClose={2500} />
        </Suspense>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <WrappedApp />
    </React.StrictMode>,
    document.getElementById('root'),
);

const SWConfig = {
    onSuccess: () => {
        // console.log('Content is cached for offline use.');
    },
    onUpdate: (registration) => {
        toast.success(i18n.t('alerts.offline-cached-reload'), {
            autoClose: false,
            onClose: async () => {
                await registration.unregister();
                // Makes Workbox call skipWaiting()
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                // Once the service worker is unregistered, we can reload the page to let
                // the browser download a fresh copy of our app (invalidating the cache)
                window.location.reload();
            },
        });
    },
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(SWConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
