import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from '../../Atoms/Icon/Icon';
import Title from '../../Atoms/Title/Title';

import './Card.scss';

const _CLASSNAME = 'c_card';

const TypeProduct = ({ number, title, subTitle, icon, to, iconColor }) => {
    return (
        <div className={`${_CLASSNAME}__product d-flex align-items-start`}>
            <div className={`${_CLASSNAME}__product-number mr-1`}>
                <b>{number}</b>
            </div>
            <div className={`${_CLASSNAME}__product-text`}>
                {title}
                {subTitle !== '' && (
                    <div>
                        <i dangerouslySetInnerHTML={{ __html: subTitle }}></i>
                    </div>
                )}
            </div>
            {to && to !== '' && (
                <div className={`${_CLASSNAME}__product-icon ${_CLASSNAME}__product-icon--${iconColor}`}>
                    <Icon icon={icon} size={1.2} className={`${_CLASSNAME}__product-icon-icon`} />
                </div>
            )}
        </div>
    );
};

const TypeOrder = ({ date, title, subTitle, icon, iconColor }) => {
    return (
        <div className={`${_CLASSNAME}__order d-flex align-items-start`}>
            <div className={`${_CLASSNAME}__order-date mr-1`}>
                <b>{date}</b>
            </div>
            <div className={`${_CLASSNAME}__order-text`}>
                {title}
                {subTitle !== '' && (
                    <div>
                        <i dangerouslySetInnerHTML={{ __html: subTitle }}></i>
                    </div>
                )}
            </div>
            <div className={`${_CLASSNAME}__order-icon ${_CLASSNAME}__order-icon--${iconColor}`}>
                <Icon icon={icon} className={`${_CLASSNAME}__order-icon-icon`} />
            </div>
        </div>
    );
};

const TypeContact = ({ title, subTitle, img, tel }) => {
    return (
        <div className={`${_CLASSNAME}__contact`}>
            <Title title={title} color="white" className="mb-2 d-md-none" />
            <div className={`${_CLASSNAME}__contact-content d-flex align-items-center`}>
                <img src={img} alt={title} className={`${_CLASSNAME}__contact-img mr-3 mr-md-4 h_noselect h_nodrag`} />
                <div className={`${_CLASSNAME}__contact-text`}>
                    <Title title={title} color="white" className="d-none d-md-block mb-4" />
                    {subTitle !== '' && <div dangerouslySetInnerHTML={{ __html: subTitle }} />}
                    <a
                        className={`${_CLASSNAME}__contact-tel d-flex align-items-center h_stretched-link`}
                        href={`tel:${tel}`}
                    >
                        <Icon icon="call" className={`${_CLASSNAME}__contact-icon mr-1`} size={0.8} />
                        <span>{tel}</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

const Card = (props) => {
    const { children, className, grouped, type, to, onClick } = props;
    const Tag = to !== '' ? Link : 'div';
    return (
        <Tag
            className={[_CLASSNAME, className, `${_CLASSNAME}--${type}`, `${grouped ? `${_CLASSNAME}--grouped` : ''}`]
                .filter((x) => x !== '')
                .join(' ')}
            to={to}
            onClick={onClick}
        >
            <div className={`${_CLASSNAME}__inner ${_CLASSNAME}__inner--${type}`}>
                {(type === 'product' || type === 'cart') && <TypeProduct {...props} />}
                {type === 'order' && <TypeOrder {...props} />}
                {type === 'contact' && <TypeContact {...props} />}
                {children}
            </div>
        </Tag>
    );
};

export default Card;

Card.propTypes = {
    children: PropTypes.node,
    type: PropTypes.oneOf(['default', 'product', 'cart', 'order', 'contact']),
    className: PropTypes.string,
    grouped: PropTypes.bool,
    to: PropTypes.string,
    onClick: PropTypes.func,
    number: PropTypes.string,
    date: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    tel: PropTypes.string,
    img: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.oneOf(['primary', 'secondary']),
};
Card.defaultProps = {
    className: '',
    type: 'default',
    grouped: false,
    to: '',
    number: '',
    date: '',
    title: '',
    subTitle: '',
    tel: '',
    img: '',
    icon: 'next',
    iconColor: 'secondary',
};
