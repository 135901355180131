import { useEffect, useCallback, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersAsync } from '../../redux/ordersSlice';
import { useTranslation } from 'react-i18next';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Card from '../../components/Molecules/Card/Card';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import NoResultCard from '../../components/Organisms/NoResultCard/NoResultCard';

import './Orders.scss';

const _CLASSNAME = 'c_orders';
const _IMG = 'orders.png';

const Orders = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const orders = useSelector((state) => state.orders);

    // load data
    const getData = useCallback(() => {
        if (orders.orders.length < 1) dispatch(getOrdersAsync());
    }, [dispatch, orders.orders.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    if (orders.isLoading) {
        return (
            <DefaultLayout img={_IMG}>
                <Preloader color="white" includeOverlay={true} />
            </DefaultLayout>
        );
    }

    return (
        <DefaultLayout img={_IMG}>
            {orders.orders.length < 1 ? (
                <NoResultCard
                    title={t('containers.orders.empty-orders-title')}
                    text={t('containers.orders.empty-orders-text')}
                />
            ) : (
                <Fragment>
                    {orders.orders.map((order) => (
                        <Card
                            key={order.id}
                            className={`${_CLASSNAME}__item`}
                            type="order"
                            to={`/orders/${order.simpleId}/`}
                            date={order.date}
                            title={order.title}
                            icon="next"
                            iconColor="secondary"
                        />
                    ))}
                </Fragment>
            )}
        </DefaultLayout>
    );
};

export default Orders;
