import PropTypes from 'prop-types';

import './Select.scss';
import Icon from '../Icon/Icon';
import AlertText from '../AlertText/AlertText';

const _CLASSNAME = 'c_select';

const Select = ({
    className,
    label,
    value,
    name,
    id,
    onChange,
    error,
    errorMessage,
    variant,
    disabled,
    placeholder,
    options,
}) => {
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };
    return (
        <div className={[_CLASSNAME, className, `${_CLASSNAME}--${variant}`].filter((c) => c !== '').join(' ')}>
            <label
                htmlFor={id}
                className={[`${_CLASSNAME}__label`, `${error ? 's_error' : ''}`].filter((c) => c !== '').join(' ')}
            >
                {label}
            </label>
            <div className={`${_CLASSNAME}__input-wrapper`}>
                <select
                    name={name}
                    id={id}
                    onChange={handleChange}
                    className={[`${_CLASSNAME}__input`, `${error ? 's_error' : ''}`].filter((c) => c !== '').join(' ')}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                >
                    {options.map((option, index) => (
                        <option
                            key={`select-option-${option.value}-${index}`}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
                <div className={`${_CLASSNAME}__arrow d-flex align-items-center justify-content-center`}>
                    <Icon icon="dropdown" size={2} />
                </div>
            </div>
            {error && errorMessage !== '' && (
                <span className={`${_CLASSNAME}__error-msg`}>
                    <AlertText icon="error" text={errorMessage} color={variant === 'inverted' ? 'white' : 'primary'} />
                </span>
            )}
        </div>
    );
};

export default Select;

Select.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'inverted']),
    placeholder: PropTypes.string,
    options: PropTypes.array,
};
Select.defaultProps = {
    className: '',
    label: '',
    value: '',
    name: '',
    id: 'id',
    disabled: false,
    error: false,
    errorMessage: '',
    variant: 'default',
    placeholder: '',
    options: [],
};
