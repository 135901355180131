import PropTypes from 'prop-types';
import './Wysiwyg.scss';

const _CLASSNAME = 'c_wysiwyg';

const Wysiwyg = ({ children, className, color, centered, html }) => {
    if (html !== '') {
        return (
            <article
                className={`${_CLASSNAME} ${className} ${_CLASSNAME}--${color} ${
                    centered ? `${_CLASSNAME}--centered` : ''
                }`}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        );
    }
    return (
        <article
            className={`${_CLASSNAME} ${className} ${_CLASSNAME}--${color} ${
                centered ? `${_CLASSNAME}--centered` : ''
            }`}
        >
            {children}
        </article>
    );
};
export default Wysiwyg;

Wysiwyg.propTypes = {
    color: PropTypes.oneOf(['default', 'white']),
    centered: PropTypes.bool,
    html: PropTypes.string,
    className: PropTypes.string,
};
Wysiwyg.defaultProps = {
    color: 'default',
    centered: false,
    html: '',
    className: '',
};
