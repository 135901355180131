import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from '../api/api';

const initialState = {
    isLoading: false,
    isPosting: false,
    cart: [],
    count: 0,
};

export const getCartAsync = createAsyncThunk('cart/getCartAsync', async (payload) => {
    const res = await API.get({
        endpoint: 'cart',
    });
    return res.data;
});

export const addOrUpdateAsync = createAsyncThunk('cart/addOrUpdateAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'cart/addOrUpdate',
        data: payload,
    });
    return res.data;
});
export const removeFromCartAsync = createAsyncThunk('cart/removeFromCartAsync', async (payload) => {
    const res = await API.post({
        endpoint: 'cart/delete',
        data: payload,
    });
    return res.data;
});

export const cartSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: {
        // getCartAsync
        [getCartAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getCartAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [getCartAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.cart = action.payload.cart;
            state.count = action.payload.cart.length;
        },

        // addOrUpdateAsync
        [addOrUpdateAsync.pending]: (state) => {
            state.isPosting = true;
        },
        [addOrUpdateAsync.rejected]: (state) => {
            state.isPosting = false;
        },
        [addOrUpdateAsync.fulfilled]: (state, action) => {
            state.isPosting = false;
            state.cart = action.payload.cart;
            state.count = action.payload.cart.length;
        },

        // removeFromCartAsync
        [removeFromCartAsync.pending]: (state) => {
            state.isPosting = true;
        },
        [removeFromCartAsync.rejected]: (state) => {
            state.isPosting = false;
        },
        [removeFromCartAsync.fulfilled]: (state, action) => {
            state.isPosting = false;
            state.cart = action.payload.cart;
            state.count = action.payload.cart.length;
        },

        // clear the cart when an order is sucesfully added!
        'addOrderAsync/fulfilled': (state, action) => {
            state.cart = [];
        },
        // when logged out remove cart
        'logoutAsync/fulfilled': (state, action) => {
            state.cart = [];
        },

        // when the user logged in: update the cart counter
        'loginAsync/fulfilled': (state, action) => {
            if (action.payload.success) state.count = action.payload.cart.count;
        },
        'validateTokenAsync/fulfilled': (state, action) => {
            if (action.payload.success) state.count = action.payload.cart.count;
        },

        // clear the current cart items if the user switched from client (only used for user role "reselller")
        'switchClientAsync/fulfilled': (state, action) => {
            state.cart = [];
            if (action.payload.success) state.count = action.payload.cart.count;
        },
    },
});

// export const { } = cartSlice.actions;

export default cartSlice.reducer;
