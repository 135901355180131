import { useTranslation } from 'react-i18next';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Card from '../../components/Molecules/Card/Card';
import Title from '../../components/Atoms/Title/Title';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';

const _IMG = 'login.png';

const NoMatch = () => {
    const { t } = useTranslation();

    return (
        <DefaultLayout img={_IMG}>
            <Card>
                <Title>{t('containers.404.title')}</Title>
                <Wysiwyg html={t('containers.404.text')}></Wysiwyg>
            </Card>
        </DefaultLayout>
    );
};

export default NoMatch;
