import PropTypes from 'prop-types';
import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { getFormattedDate } from '../../../utilities/date';
import {
    getCheckoutFormdataAsync,
    addOrderAsync,
    saveTempCheckoutData,
    clearTempCheckoutData,
} from '../../../redux/ordersSlice';

import Title from '../../Atoms/Title/Title';
import Button from '../../Atoms/Button/Button';
import Input from '../../Atoms/Input/Input';
import Select from '../../Atoms/Select/Select';
import AutocompleteSelect from '../../Atoms/AutocompleteSelect/AutocompleteSelect';
import Preloader from '../../Atoms/Preloader/Preloader';
import Radiobutton from '../../Atoms/Radiobutton/Radiobutton';

import './CheckoutForm.scss';

const _CLASSNAME = 'c_checkout-form';

const CheckoutForm = ({ className }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let history = useHistory();
    const isLoading = useSelector((state) => state.orders.isLoading);
    const tempCheckoutData = useSelector((state) => state.orders.tempCheckoutData);
    const checkoutData = useSelector((state) => state.orders.checkoutData);

    const [errors, setErrors] = useState([]);
    const [reference, setReference] = useState(tempCheckoutData.reference || '');
    const [deliveryType, setDeliveryType] = useState('');
    const [deliveryDate, setDeliveryDate] = useState(tempCheckoutData.deliveryDate || '');
    const [deliveryAddress, setDeliveryAddress] = useState(tempCheckoutData.deliveryAddress || {});
    const [remark, setRemark] = useState(tempCheckoutData.remark || '');
    const [newAddress, setNewAddress] = useState(tempCheckoutData.newAddress || '');

    const bundleData = (temp = false) => {
        if (temp) {
            return {
                reference,
                deliveryType,
                deliveryDate,
                deliveryAddress,
                remark,
                newAddress,
            };
        }
        return {
            reference,
            deliveryType,
            deliveryDate,
            deliveryAddressId: deliveryAddress.value,
            remark,
            newAddress,
        };
    };

    const isValid = () =>
        deliveryDate !== '' && Object.keys(deliveryAddress).length > 0 && deliveryAddress.value !== '';

    // load data
    const getData = useCallback(() => {
        dispatch(getCheckoutFormdataAsync()).then((res) => {
            setDeliveryType(res.payload.data.preferredDeliveryType);

            // REVIEW: this does not seem to work like expected:
            // the default fallback values for 'deliveryDate' and 'deliveryAddress' are empty strings
            // the API returns disabled placeholder values with an empty value
            // therefore the placeholders are always selected... and never the fist actual option
            // so the code below is useless...

            // const previousDeliverySelectionExists =
            //     res.payload.data.dates.filter((item) => item.value === tempCheckoutData.deliveryDate).length === 1;
            // console.log('previousDeliverySelectionExists', previousDeliverySelectionExists);
            // if (!previousDeliverySelectionExists) setDeliveryDate(res.payload.data.dates[0].value);
            // const previousAddressSelectionExists =
            //     tempCheckoutData.deliveryAddress &&
            //     Object.keys(tempCheckoutData.deliveryAddress).length > 0 &&
            //     tempCheckoutData.deliveryAddress.value &&
            //     res.payload.data.addresses.filter((item) => item.value === tempCheckoutData.deliveryAddress.value)
            //         .length === 1;
            // if (!previousAddressSelectionExists) setDeliveryAddress(res.payload.data.addresses[0]);
        });
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    // save the temp data in the order on change
    useEffect(() => {
        dispatch(saveTempCheckoutData(JSON.parse(JSON.stringify(bundleData(true)))));
        // eslint-disable-next-line
    }, [reference, deliveryType, deliveryDate, deliveryAddress, remark, newAddress]);

    const handleSubmit = () => {
        dispatch(addOrderAsync(bundleData())).then((d) => {
            if (d.error) return toast.error(t('alerts.error'));
            setErrors(d.payload.errors);
            if (d.payload.success) {
                dispatch(clearTempCheckoutData());
                history.push(`/success/${d.payload.orderId}/`);
            }
        });
    };

    return (
        <div className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}>
            <Title title={t('containers.cartcheckout.title')} color="secondary" tag="span" />
            <Title title={getFormattedDate()} className="mb-3" />
            <form className="mt-4">
                <Input
                    name="reference"
                    id="reference"
                    value={reference}
                    type="text"
                    onChange={(e) => {
                        setReference(e.target.value);
                    }}
                    label={t('order.reference')}
                    className={`${_CLASSNAME}__input`}
                    error={errors.reference}
                />
                <div className={`${_CLASSNAME}__input d-flex`}>
                    <Radiobutton
                        name={`deliveryType`}
                        id={`deliver`}
                        value={'deliver'}
                        isChecked={deliveryType === 'deliver'}
                        onChange={(e) => {
                            setDeliveryType('deliver');
                        }}
                        label={t('order.deliver')}
                        className="mr-4"
                        error={errors.deliveryType}
                    />
                    <Radiobutton
                        name={`deliveryType`}
                        id={`pickup`}
                        value={'pickup'}
                        isChecked={deliveryType === 'pickup'}
                        onChange={(e) => {
                            setDeliveryType('pickup');
                        }}
                        label={t('order.pickup')}
                        error={errors.deliveryType}
                    />
                </div>
                <Select
                    name={`deliveryDate`}
                    id={`deliveryDate`}
                    value={deliveryDate}
                    onChange={(e) => {
                        setDeliveryDate(e.target.value);
                    }}
                    label={t('order.deliverydate')}
                    options={
                        checkoutData.dates
                            ? checkoutData.dates.map((item) => {
                                  const label = item.label
                                      .replace('{{week}}', t('order.week'))
                                      .replace('{{choose-week}}', t('order.choose-week'));
                                  return {
                                      ...item,
                                      label,
                                  };
                              })
                            : []
                    }
                    className={`${_CLASSNAME}__input`}
                    error={errors.deliveryDate}
                />
                <AutocompleteSelect
                    className={`${_CLASSNAME}__input`}
                    id={`deliveryAddressId`}
                    value={deliveryAddress}
                    label={t('order.address')}
                    placeholder={`${t('order.choose-address')}...`}
                    error={errors.deliveryAddressId}
                    options={
                        checkoutData.addresses
                            ? checkoutData.addresses.map((item) => {
                                  const label = item.label.replace('{{new-address}}', t('order.new-address'));
                                  return {
                                      ...item,
                                      label,
                                  };
                              })
                            : []
                    }
                    onChange={(data) => {
                        setDeliveryAddress(data);
                        if (data.value !== 'newAddress') setNewAddress('');
                    }}
                    noOptionsMessage={t('general.no-results')}
                />

                {deliveryAddress.value && deliveryAddress.value === 'newAddress' && (
                    <Input
                        type="textarea"
                        name="newAddress"
                        id="newAddress"
                        value={newAddress}
                        onChange={(e) => {
                            setNewAddress(e.target.value);
                        }}
                        label={t('order.new-address')}
                        className={`${_CLASSNAME}__input`}
                        error={errors.newAddress}
                    />
                )}
                <Input
                    name="remark"
                    id="remark"
                    value={remark}
                    type="textarea"
                    onChange={(e) => {
                        setRemark(e.target.value);
                    }}
                    label={t('order.remarks')}
                    error={errors.remark}
                />
            </form>
            <div className={`d-flex justify-content-center mt-4`}>
                <Button
                    label={t('containers.cartcheckout.submit')}
                    variant="secondary"
                    disabled={isLoading || !isValid()}
                    onClick={handleSubmit}
                />
            </div>
            {isLoading && <Preloader color="primary" overlayColor="white" includeOverlay={true} />}
        </div>
    );
};
export default CheckoutForm;

CheckoutForm.propTypes = {
    className: PropTypes.string,
};
CheckoutForm.defaultProps = {};
