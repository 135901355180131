import PropTypes from 'prop-types';

import './Default.scss';

const _CLASSNAME = 'c_layout-default';

const DefaultLayout = ({ children, className, img }) => {
    return (
        <div className={[_CLASSNAME, className].filter((x) => x !== '').join(' ')}>
            <div className="container">
                <div className="row">
                    <div className={`col-12 col-md-6 ${_CLASSNAME}__col-img`}>
                        <div
                            className={`${_CLASSNAME}__img`}
                            style={
                                img !== ''
                                    ? {
                                          backgroundImage: `url("${process.env.PUBLIC_URL}/images/layout-images/${img}")`,
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={`${_CLASSNAME}__content col-12 col-md-6 d-flex flex-column`}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default DefaultLayout;

DefaultLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    img: PropTypes.string,
};
DefaultLayout.defaultProps = {
    className: '',
    img: '',
};
