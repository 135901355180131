import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import URLSearchParams from '@ungap/url-search-params';

import cookieAPI from './api/cookieAPI';
import { getBaseUrl } from './api/baseUrl';

// https://phrase.com/blog/posts/localizing-react-apps-with-i18next/

const _SUPPORTEDLOCALES = [
    {
        locale: 'en-GB',
        label: 'English',
    },
    {
        locale: 'nl-NL',
        label: 'Nederlands',
    },
    {
        locale: 'de-DE',
        label: 'Deutsch',
    },
    {
        locale: 'fr-FR',
        label: 'Français',
    },
    {
        locale: 'es-ES',
        label: 'Español',
    },
];
const _FALLBACK_LOCALE = _SUPPORTEDLOCALES[0];
const _LANGUAGEKEY = 'language';

const getLanguageByLocale = (locale) => {
    const res = _SUPPORTEDLOCALES.filter((loc) => loc.locale === locale);
    if (res.length) return res[0];
    return 'unknown';
};
const languageIsPreviouslySet = () => {
    return cookieAPI.get(_LANGUAGEKEY) !== false;
};
const getLanguage = (checkForURLQuery = false) => {
    let res = cookieAPI.get(_LANGUAGEKEY);
    // if we also need to check the URL query string for the presence of a lang=x parameter, this is leading!
    if (checkForURLQuery) {
        const urlParams = new URLSearchParams(window.location.search);
        let lang = urlParams.get('lang');
        if (lang) {
            // always check of the lang in the URL is supported! Of not we still use the fallback language
            const filtered = _SUPPORTEDLOCALES.filter(
                (loc) => loc.locale.split('-')[0].toUpperCase() === lang.toUpperCase(),
            );
            if (filtered.length === 1) {
                res = filtered[0].locale;
            }
        }
    }
    return res || _FALLBACK_LOCALE.locale;
};
const getSupportedLocales = (key = false) => {
    if (!key) return _SUPPORTEDLOCALES;
    return _SUPPORTEDLOCALES.map((loc) => loc[key]);
};
const saveLanguage = (language) => {
    return cookieAPI.save(_LANGUAGEKEY, language);
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        backend: {
            backends: [HttpApi],
            backendOptions: [
                {
                    loadPath: `${getBaseUrl()}/locales/{{lng}}/`,
                    // crossDomain: true,
                    // reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
                },
            ],
        },
        ns: ['translation'],
        lng: getLanguage(true),
        load: 'currentOnly',
        fallbackLng: _FALLBACK_LOCALE.locale,
        supportedLngs: getSupportedLocales('locale'),
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        debug: false,
    });

export default i18n;
export {
    getLanguage,
    saveLanguage,
    getSupportedLocales,
    _FALLBACK_LOCALE,
    getLanguageByLocale,
    languageIsPreviouslySet,
};
