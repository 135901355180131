import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Button from '../../components/Atoms/Button/Button';
import Input from '../../components/Atoms/Input/Input';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';
import AlertText from '../../components/Atoms/AlertText/AlertText';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import ContactCard from '../../components/Organisms/ContactCard/ContactCard';

import { setPasswordAsync } from '../../redux/userSlice';

import './Password.scss';

const _CLASSNAME = 'c_password';
const _IMG = 'password.png';

const Password = () => {
    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const isLoading = user.isLoading;

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [errors, setErrors] = useState({});

    const handeFormSubmit = async (e) => {
        e.preventDefault();
        dispatch(
            setPasswordAsync({
                password,
                passwordRepeat,
                hash: params.hash,
            }),
        )
            .then((res) => {
                if (res.payload.success) {
                    toast.success(t('containers.password.success'));
                    history.push('/login/');
                } else {
                    setErrors(res.payload.errors);
                }
            })
            .catch(() => {
                toast.error(t('alerts.error'));
            });
    };

    return (
        <DefaultLayout img={_IMG}>
            <div className={`${_CLASSNAME}__inner mb-8`}>
                <form className={_CLASSNAME} onSubmit={handeFormSubmit} noValidate>
                    <Title color="white" className="mb-3">
                        {t('containers.password.title')}
                    </Title>
                    <Wysiwyg color="white" html={t('containers.password.text')} />
                    <Input
                        type="password"
                        name={`password`}
                        id={`password`}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        label={t('containers.password.password')}
                        className={`${_CLASSNAME}__input mb-3`}
                        error={errors.password && errors.password !== ''}
                        errorMessage={t(`containers.password.errors.password.${errors.password}`)}
                        variant="inverted"
                        disabled={isLoading}
                    />
                    <Input
                        type="password"
                        name={`passwordrepeat`}
                        id={`passwordrepeat`}
                        value={passwordRepeat}
                        onChange={(e) => {
                            setPasswordRepeat(e.target.value);
                        }}
                        label={t('containers.password.password-repeat')}
                        className={`${_CLASSNAME}__input mb-3`}
                        error={errors.password && errors.password !== ''}
                        errorMessage={t(`containers.passwordRepeat.errors.password.${errors.password}`)}
                        variant="inverted"
                        disabled={isLoading}
                    />
                    {errors.general && errors.general !== '' && (
                        <div className={`${_CLASSNAME}__error`}>
                            <AlertText
                                icon="error"
                                text={t(`containers.password.errors.general.${errors.general}`)}
                                color="white"
                            />
                        </div>
                    )}
                    <div className="d-flex justify-content-between mt-4">
                        <Button
                            label={t('containers.password.send')}
                            type="submit"
                            variant="white"
                            disabled={isLoading}
                        />
                        <Button
                            inline={true}
                            label={t('containers.password.to-login')}
                            to="/login/"
                            variant="white"
                            size="small"
                        />
                    </div>
                    <Preloader includeOverlay={true} active={isLoading} />
                </form>
            </div>
            <ContactCard />
        </DefaultLayout>
    );
};

export default Password;
