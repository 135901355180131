import { formatKG } from './unitconversions';
import i18n from '../i18n';

// We need to refactor the data structure for the cart cards.
const refactorDataForCard = (cartItem, addLinksToCards) => {
    let subTitle = '';
    switch (cartItem.product.type) {
        case 'bulk': {
            subTitle = cartItem.quantity.silos
                .map((silo) => `${formatKG(silo.weight)} ${i18n.t('order.silo')} ${silo.label}`)
                .join('<br/>');
            break;
        }
        case 'piece': {
            if (cartItem.quantity.kg <= 0) {
                // NOTE: before previous release the "pieces" quantity key was not yet used..
                // this was added in a later app-version
                // therefore we create a fallback to show the previously used key for pieces (which is "bags")
                const amount = cartItem.quantity.pieces || cartItem.quantity.bags;
                subTitle = `${i18n.t('order.units.pieces')}: ${amount}`;
            } else {
                subTitle = `${formatKG(cartItem.quantity.kg)}`;
            }
            break;
        }
        default: {
            subTitle = `${formatKG(cartItem.quantity.kg)}`;
            break;
        }
    }
    const returnData = {
        to: `/cart/edit/${cartItem.id}`,
        number: cartItem.product.simpleId,
        title: cartItem.product.title,
        subTitle,
    };
    if (!addLinksToCards) {
        delete returnData['to'];
    }
    return returnData;
};

// this function groups the single array of products/cartItems
// if there are multiple products with the same simpleId group them together as one!
// we also refactor the data passed to the cardgroup component. The data structure is somehwat different from the way the cart data structure
export function groupCartItems(cartItems, addLinksToCards = true) {
    const formattedCart = [];
    const uniqueProductSimpleIds = [];
    cartItems.forEach((cartItem) => {
        if (!uniqueProductSimpleIds.includes(cartItem.product.simpleId)) {
            formattedCart.push({
                productSimpleId: cartItem.product.simpleId,
                items: [refactorDataForCard(cartItem, addLinksToCards)],
            });
            uniqueProductSimpleIds.push(cartItem.product.simpleId);
        } else {
            const index = formattedCart.findIndex((item) => item.productSimpleId === cartItem.product.simpleId);
            formattedCart[index].items.push(refactorDataForCard(cartItem, addLinksToCards));
        }
    });
    return formattedCart;
}
