import PropTypes from 'prop-types';

import './Title.scss';

const _CLASSNAME = 'c_title';

const Title = ({ children, title, color, size, tag, className, html, style }) => {
    const Tag = typeof tag === 'number' ? `h${tag}` : tag;

    if (html !== '') {
        return (
            <Tag
                className={[_CLASSNAME, `${_CLASSNAME}--color-${color}`, `${_CLASSNAME}--size-${size}`, className]
                    .filter((c) => c !== '')
                    .join(' ')}
                dangerouslySetInnerHTML={{ __html: html }}
                style={style}
            />
        );
    }
    return (
        <Tag
            className={[_CLASSNAME, `${_CLASSNAME}--color-${color}`, `${_CLASSNAME}--size-${size}`, className]
                .filter((c) => c !== '')
                .join(' ')}
            style={style}
        >
            {title}
            {children}
        </Tag>
    );
};

export default Title;

Title.propTypes = {
    title: PropTypes.string.isRequired,
    size: PropTypes.oneOf([1, 2]),
    tag: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 'span', 'div']),
    children: PropTypes.node,
    color: PropTypes.oneOf(['primary', 'secondary', 'white']),
    className: PropTypes.string,
    html: PropTypes.string,
    style: PropTypes.object,
};
Title.defaultProps = {
    title: '',
    size: 1,
    tag: 1,
    color: 'primary',
    className: '',
    html: '',
    style: {},
};
