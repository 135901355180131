import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getCartAsync } from '../../redux/cartSlice';

import DefaultLayout from '../../components/Layouts/Default/Default';
import CardGroup from '../../components/Organisms/CardGroup/CardGroup';
import Button from '../../components/Atoms/Button/Button';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import NoResultCard from '../../components/Organisms/NoResultCard/NoResultCard';

import { sumTotalCartWeight } from '../../utilities/unitconversions';
import { groupCartItems } from '../../utilities/products';

import './Cart.scss';

const _CLASSNAME = 'c_cart';
const _IMG = 'cart.png';

const Cart = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const cartItems = useSelector((state) => state.cart.cart);

    // load data
    const getData = useCallback(() => {
        if (cart.cart.length < 1) dispatch(getCartAsync());
    }, [dispatch, cart.cart.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    if (cart.isLoading) {
        return (
            <DefaultLayout img={_IMG}>
                <Preloader color="white" includeOverlay={true} />
            </DefaultLayout>
        );
    }

    if (cartItems.length <= 0) {
        return (
            <DefaultLayout img={_IMG}>
                <NoResultCard
                    title={t('containers.cart.empty-cart-title')}
                    text={t('containers.cart.empty-cart-text')}
                />
                <div className={`d-flex justify-content-center justify-content-md-start ${_CLASSNAME}__submit-wrapper`}>
                    <Button label={t('containers.cart.empty-cart-button')} variant="white" to="/products/" />
                </div>
            </DefaultLayout>
        );
    }

    return (
        <DefaultLayout img={_IMG}>
            <div className={`${_CLASSNAME}__items`}>
                {groupCartItems(cartItems).map((cartItem, index) => (
                    <CardGroup
                        key={`cartItem-${index}`}
                        className={`${_CLASSNAME}__item`}
                        cards={cartItem.items}
                        cardIconColor="primary"
                    />
                ))}
            </div>
            {cartItems.length > 1 && (
                <div className={`${_CLASSNAME}__total-wrapper`}>
                    <hr />
                    <p className={`${_CLASSNAME}__total`}>
                        <i>
                            {t('order.total')} {sumTotalCartWeight(cartItems)}
                        </i>
                    </p>
                </div>
            )}
            <div className={`d-flex justify-content-center justify-content-md-start ${_CLASSNAME}__submit-wrapper`}>
                <Button label={t('containers.cart.submit')} variant="secondary" to="/cart/checkout/" />
            </div>
        </DefaultLayout>
    );
};
export default Cart;
