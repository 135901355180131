export const _LOWEST_PALLET_VALUE = 0.01;

export const sumSiloWeight = (silos = [], pretty = true) => {
    let total = 0;
    silos.forEach((s) => {
        total = total + s.weight;
    });
    if (!pretty) return total;
    return formatKG(total);
};

export const sumTotalCartWeight = (cartItems) => {
    let total = 0;
    cartItems.forEach((item) => {
        if (item.product.type === 'bulk') {
            total = total + sumSiloWeight(item.quantity.silos, false);
        } else {
            total = total + item.quantity.kg;
        }
    });
    return formatKG(total);
};

export const isNumber = (value) => typeof value === 'number' && isFinite(value);

export const round = (num, decimals = 2) => Number(Math.round(num + 'e' + decimals) + 'e-' + decimals);

const _palletRound = (val) => {
    const preRound = round(val);
    if (val > 0 && preRound < _LOWEST_PALLET_VALUE) return _LOWEST_PALLET_VALUE;
    return preRound;
};

export const calculateWeights = (formType, inputName, newValue, weights) => {
    const hasRequiredParam_bag = weights.bags && weights.bags > 0;
    const hasRequiredParam_pallet = weights.pallet && weights.pallet > 0;
    switch (formType) {
        case 'bag': {
            // from KG to others
            if (inputName === 'kg') {
                const d = {
                    kg: newValue,
                };
                if (hasRequiredParam_bag) d['bags'] = round(newValue / weights.bags);
                if (hasRequiredParam_pallet) d['pallets'] = _palletRound(newValue / weights.pallet);
                return d;
            }
            // from bags to others
            else if (inputName === 'bags') {
                const d = {
                    bags: newValue,
                };
                if (hasRequiredParam_bag) {
                    const kg = newValue * weights.bags;
                    d['kg'] = kg;
                    if (hasRequiredParam_pallet) d['pallets'] = _palletRound(kg / weights.pallet);
                }
                return d;
            }
            // from pallets to others
            else {
                const d = {
                    pallets: _palletRound(newValue),
                };
                if (hasRequiredParam_pallet) {
                    const kg = newValue * weights.pallet;
                    d['kg'] = kg;
                    if (hasRequiredParam_bag) d['bags'] = round(kg / weights.bags);
                }
                return d;
            }
        }
        // PIECE
        case 'piece': {
            if (inputName === 'pieces') {
                const d = {
                    pieces: newValue,
                };
                if (hasRequiredParam_bag) d['kg'] = newValue * weights.bags;
                return d;
            }
            const d = {
                kg: newValue,
            };
            if (hasRequiredParam_bag) d['pieces'] = round(newValue / weights.bags);
            return d;
        }
        // BiGBAG
        case 'bigbag': {
            if (inputName === 'bigbags') {
                const d = {
                    bigbags: newValue,
                };
                if (hasRequiredParam_bag) d['kg'] = newValue * weights.bags;
                return d;
            }
            const d = {
                kg: newValue,
            };
            if (hasRequiredParam_bag) d['bigbags'] = round(newValue / weights.bags);
            return d;
        }
        // BULK (has really no calculations....)
        case 'bulk': {
            return {
                kg: newValue,
            };
        }
        default: {
            return {};
        }
    }
};

export const formatKG = (number = 0, addUnit = true, unit = 'kg') => {
    if (!number) return '';
    const n = number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    if (!addUnit) return n;
    return `${n} ${unit}`;
};
