import PropTypes from 'prop-types';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import Logo from '../../components/Atoms/Logo/Logo';

import './LoadingScreen.scss';

const _CLASSNAME = 'c_loading-screen';

const LoadingScreen = ({ className }) => (
    <div className={`${_CLASSNAME} ${className} d-flex justify-content-center align-items-center p-4`}>
        <div className="d-flex flex-column justify-content-center align-items-center">
            <Logo className={`${_CLASSNAME}__logo`} />
            <Preloader color="white" />
        </div>
    </div>
);

export default LoadingScreen;

LoadingScreen.propTypes = {
    className: PropTypes.string,
};
LoadingScreen.defaultProps = {
    className: '',
};
