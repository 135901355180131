import PropTypes from 'prop-types';

import './Input.scss';
import Icon from '../Icon/Icon';
import AlertText from '../AlertText/AlertText';

const _CLASSNAME = 'c_input';

const Input = ({
    className,
    type,
    label,
    value,
    name,
    id,
    onChange,
    error,
    errorMessage,
    variant,
    disabled,
    placeholder,
    spinners,
    onMin,
    onPlus,
}) => {
    const inputValue = !value && value !== 0 ? '' : value;
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };
    return (
        <div className={[_CLASSNAME, className, `${_CLASSNAME}--${variant}`].filter((c) => c !== '').join(' ')}>
            <label
                htmlFor={id}
                className={[`${_CLASSNAME}__label ${_CLASSNAME}__label--${type}`, `${error ? 's_error' : ''}`]
                    .filter((c) => c !== '')
                    .join(' ')}
            >
                {label}
            </label>
            <div className={`${_CLASSNAME}__input-wrapper`}>
                {type === 'textarea' ? (
                    <textarea
                        name={name}
                        id={id}
                        onChange={handleChange}
                        className={[`${_CLASSNAME}__input ${_CLASSNAME}__input--${type}`, `${error ? 's_error' : ''}`]
                            .filter((c) => c !== '')
                            .join(' ')}
                        value={inputValue}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                ) : (
                    <input
                        type={type}
                        name={name}
                        id={id}
                        value={inputValue}
                        onChange={handleChange}
                        disabled={disabled}
                        placeholder={placeholder}
                        className={[`${_CLASSNAME}__input ${_CLASSNAME}__input--${type}`, `${error ? 's_error' : ''}`]
                            .filter((c) => c !== '')
                            .join(' ')}
                    />
                )}
                {spinners && (
                    <div className={`${_CLASSNAME}__spinners d-flex`}>
                        <button className={`${_CLASSNAME}__spinner ${_CLASSNAME}__spinner--remove `} onClick={onMin}>
                            <Icon icon="remove" size={1.4} />
                        </button>
                        <button className={`${_CLASSNAME}__spinner ${_CLASSNAME}__spinner--add`} onClick={onPlus}>
                            <Icon icon="add" size={1.4} />
                        </button>
                    </div>
                )}
            </div>
            {error && errorMessage !== '' && (
                <span className={`${_CLASSNAME}__error-msg`}>
                    <AlertText icon="error" text={errorMessage} color={variant === 'inverted' ? 'white' : 'primary'} />
                </span>
            )}
        </div>
    );
};

export default Input;

Input.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(['number', 'tel', 'text', 'email', 'password', 'textarea']).isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'inverted']),
    placeholder: PropTypes.string,
    spinners: PropTypes.bool,
    onMin: PropTypes.func,
    onPlus: PropTypes.func,
};
Input.defaultProps = {
    className: '',
    type: 'text',
    label: '',
    value: '',
    name: '',
    id: 'id',
    disabled: false,
    error: false,
    errorMessage: '',
    variant: 'default',
    placeholder: '',
    spinners: false,
    onMin: () => {},
    onPlus: () => {},
};
