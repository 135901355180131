import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../api/api';

export const getProductsAsync = createAsyncThunk('products/getProductsAsync', async (payload) => {
    const res = await API.get({
        endpoint: 'products',
    });
    return res.data;
});

const initialState = {
    name: 'products',
    isLoading: false,
    products: [],
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: {
        // getProductsAsync
        [getProductsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getProductsAsync.rejected]: (state) => {
            state.isLoading = false;
        },
        [getProductsAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.products = action.payload.products;
        },

        // delete the current products if the user switched from client (only used for user role "reselller")
        'switchClientAsync/fulfilled': (state, action) => {
            state.products = [];
        },
        // when logged out remove products
        'logoutAsync/fulfilled': (state, action) => {
            state.products = [];
        },
    },
});

export const { addOrder, toggleComplete, deleteOrder } = productsSlice.actions;

export default productsSlice.reducer;
