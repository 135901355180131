import PropTypes from 'prop-types';
import { Fragment } from 'react';

import './Preloader.scss';

const _CLASSNAME = 'c_preloader';

const Overlay = ({ includeOverlay, children, overlayColor }) => {
    if (!includeOverlay) return <Fragment>{children}</Fragment>;
    return <div className={`${_CLASSNAME}__overlay ${_CLASSNAME}__overlay--${overlayColor}`}>{children}</div>;
};

const Preloader = ({ color, active, includeOverlay, overlayColor }) => {
    return (
        <div
            className={`${_CLASSNAME} ${active ? 's_active' : ''} ${
                includeOverlay ? `${_CLASSNAME}--with-overlay` : ''
            }`}
        >
            <Overlay includeOverlay={includeOverlay} overlayColor={overlayColor}>
                <div className={`${_CLASSNAME}__container`}>
                    <div className={`${_CLASSNAME}__spinner ${_CLASSNAME}__spinner--${color}`} />
                </div>
            </Overlay>
        </div>
    );
};

export default Preloader;

Preloader.propTypes = {
    active: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'white']),
    includeOverlay: PropTypes.bool,
    overlayColor: PropTypes.oneOf(['primary', 'secondary', 'white']),
};
Preloader.defaultProps = {
    active: true,
    color: 'white',
    includeOverlay: false,
    overlayColor: 'primary',
};
