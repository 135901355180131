import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Flag from 'react-flags';
import { saveLanguage, getSupportedLocales, getLanguageByLocale } from '../../../i18n';
import Icon from '../../Atoms/Icon/Icon';

import './LanguageSwitch.scss';

const _CLASSNAME = 'c_language-switch';

const LanguageSwitch = ({ className, type }) => {
    const { i18n } = useTranslation();
    const supportedLocales = getSupportedLocales();

    const changeLangIfRequired = (lang) => {
        if (i18n.language !== lang) {
            i18n.changeLanguage(lang);
            saveLanguage(lang);
        }
    };

    const handleLanguageClick = (language) => {
        changeLangIfRequired(language);
    };
    const handleSelectChange = (event) => {
        changeLangIfRequired(event.target.value);
    };

    const Wrapper = ({ children }) => (
        <div className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}>{children}</div>
    );

    const TypeSelect = ({ lang }) => {
        return (
            <div className={`${_CLASSNAME}__type-select d-flex align-items-center`}>
                <Flag
                    className={`${_CLASSNAME}__flag mr-2`}
                    basePath={`${process.env.PUBLIC_URL}/flags`}
                    country={lang.locale.split('-')[1]}
                    alt={lang.label}
                    format="svg"
                />
                <span className={`${_CLASSNAME}__type-select-label`}>{lang.label}</span>
                <Icon icon="dropdown" className="ml-auto" size={2} />
            </div>
        );
    };

    const TypeButton = ({ lang }) => {
        return (
            <button
                className={`${_CLASSNAME}__type-button`}
                key={`lang-switch-item-${lang.locale}`}
                onClick={() => {
                    handleLanguageClick(lang.locale);
                }}
            >
                <Flag
                    className={`${_CLASSNAME}__flag`}
                    basePath={`${process.env.PUBLIC_URL}/flags`}
                    country={lang.locale.split('-')[1]}
                    alt={lang.label}
                    format="svg"
                />
                <span className={`${_CLASSNAME}__type-button-label`}>{lang.label}</span>
            </button>
        );
    };

    const Selector = () => (
        <select value={i18n.language} onChange={handleSelectChange} className={`${_CLASSNAME}__select`}>
            {supportedLocales.map((lang) => (
                <option key={`lang-switch-item-${lang.locale}`} value={lang.locale}>
                    {lang.label}
                </option>
            ))}
        </select>
    );

    const lang = getLanguageByLocale(i18n.language);
    return (
        <Wrapper>
            <div className={`${_CLASSNAME} ${_CLASSNAME}__inner`}>
                {type === 'select' ? <TypeSelect lang={lang} /> : <TypeButton lang={lang} />}
                <Selector />
            </div>
        </Wrapper>
    );
};
export default LanguageSwitch;

LanguageSwitch.propTypes = {
    type: PropTypes.oneOf(['button', 'select']),
    className: PropTypes.string,
};
LanguageSwitch.defaultProps = {
    type: 'select',
    className: '',
};
