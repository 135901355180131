import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ModalRoute } from 'react-router-modal';

const ProductedRoute = (pageRoute) => {
    const user = useSelector((state) => state.user);
    if (pageRoute.protected)
        return user.loggedIn ? (
            <Route {...pageRoute} />
        ) : (
            <Redirect
                to={{
                    pathname: '/login/',
                    state: { referrer: pageRoute.location.pathname },
                }}
            />
        );
    return <Route {...pageRoute} />;
};

const ProductedModalRoute = (modalRoute) => {
    const user = useSelector((state) => state.user);
    if (modalRoute.protected)
        return user.loggedIn ? (
            <ModalRoute {...modalRoute} />
        ) : (
            <Redirect
                to={{
                    pathname: '/login/',
                    state: { referrer: modalRoute.props.modalRoute.pathname },
                }}
            />
        );
    return <ModalRoute {...modalRoute} />;
};

export { ProductedRoute, ProductedModalRoute };
