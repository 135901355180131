import React, { Fragment, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ModalContainer } from 'react-router-modal';

import NoMatch from './containers/NoMatch/NoMatch';
import Header from './components/Organisms/Header/Header';
import Footer from './components/Organisms/Footer/Footer';
import OfflineScreen from './containers/OfflineScreen/OfflineScreen';
import LoadingScreen from './containers/LoadingScreen/LoadingScreen';

import routes from './routes/routes';
import { ProductedRoute, ProductedModalRoute } from './routes/ProtectedRoute';
import { useOnlineStatus } from './utilities/useOnlineStatus';
import { validateTokenAsync, startRefreshTokenTimer, _TOKEN_KEY } from './redux/userSlice';
import cookieAPI from './api/cookieAPI';

import 'react-router-modal/css/react-router-modal.css'; // we don't need this anymore because of the customized css in app.scss
import './App.scss';

const _CLASSNAME = 'c_app';

const App = () => {
    const dispatch = useDispatch();
    const isOnline = useOnlineStatus();
    const location = useLocation();
    const history = useHistory();
    const user = useSelector((state) => state.user);

    // validate the auth token on app-load
    useEffect(() => {
        if (isOnline && !user.loggedIn && cookieAPI.get(_TOKEN_KEY)) {
            dispatch(validateTokenAsync()).then((res) => {
                if (res && res.payload && res.payload.success && res.payload.tokenValid) startRefreshTokenTimer();
            });
        }
        // eslint-disable-next-line
    }, []);

    const closeModalCustom = (e, index) => {
        if (e) e.stopPropagation();
        // if we have a modal with multiple routes to match: check what's the correct parent path
        if (Array.isArray(routes.modals[index].path)) {
            let routeMatchIndex = 0;
            routes.modals[index].parentPath.forEach((p, parentRouteIndex) => {
                const match = matchPath(location.pathname, {
                    path: p,
                    exact: false,
                });
                if (match) {
                    routeMatchIndex = parentRouteIndex;
                }
            });
            history.push(routes.modals[index].parentPath[routeMatchIndex]);
        } else {
            history.push(routes.modals[index].parentPath);
        }
    };

    // if offline: show offline screen
    if (!isOnline) return <OfflineScreen />;
    // is refreshing token: show loadingscreen before rendering app!
    if (!user.loggedIn && user.isRefreshingToken) return <LoadingScreen />;

    // create a key for the page-transitions. if a modal is rendered in a nested location we don't want the page to transition!
    const arr = location.pathname.split('/').filter((x) => x !== '');
    const pageTransitionKey = arr.length ? arr[0] : 'home';

    // render the actual view
    return (
        <Fragment>
            <Header className={`${_CLASSNAME}__header`} isLoggedIn={user.loggedIn} />
            <main className={`${_CLASSNAME}__main`}>
                <TransitionGroup component={null}>
                    <CSSTransition key={pageTransitionKey} classNames="page-fade" timeout={400}>
                        <Fragment>
                            <Switch location={location}>
                                {routes.pages.map((pageRoute, i) =>
                                    pageRoute.protected ? (
                                        <ProductedRoute key={`pageRoute-${i}`} {...pageRoute} location={location} />
                                    ) : (
                                        <Route key={`pageRoute-${i}`} {...pageRoute} location={location} />
                                    ),
                                )}
                                <NoMatch />
                            </Switch>
                        </Fragment>
                    </CSSTransition>
                </TransitionGroup>
            </main>
            <Footer className={`${_CLASSNAME}__footer`} />
            {user.loggedIn && (
                <Switch>
                    {routes.modals.map((modalRoute, i1) => {
                        // create a new function for each route index. We need to pass the indexNumber to the closeModalCustom function
                        const funct = (e) => {
                            closeModalCustom(e, i1);
                        };
                        if (Array.isArray(modalRoute.path)) {
                            return modalRoute.path.map((r, i2) => (
                                <ProductedModalRoute
                                    key={`modalRoute-${i1}-${i2}`}
                                    protected={modalRoute.protected}
                                    path={r}
                                    exact={modalRoute.exact}
                                    component={modalRoute.component}
                                    onBackdropClick={funct}
                                    outDelay={400}
                                    parentPath={modalRoute.parentPath}
                                    props={{
                                        closeModalCustom: funct,
                                        location: location,
                                    }}
                                />
                            ));
                        }
                        return (
                            <ProductedModalRoute
                                key={`modalRoute-${i1}`}
                                path={modalRoute.path}
                                exact={modalRoute.exact}
                                component={modalRoute.component}
                                onBackdropClick={funct}
                                outDelay={400}
                                parentPath={modalRoute.parentPath}
                                props={{
                                    closeModalCustom: funct,
                                    location: location,
                                }}
                            />
                        );
                    })}
                </Switch>
            )}
            <div className="c_app-modal">
                <ModalContainer
                    autoRestoreScrollPosition={true}
                    modalClassName="c_app-modal__modal"
                    modalInClassName="c_app-modal__modal--in"
                    modalOutClassName="c_app-modal__modal--out"
                    backdropClassName="c_app-modal__backdrop"
                    backdropInClassName="c_app-modal__backdrop--in"
                    backdropOutClassName="c_app-modal__backdrop--out"
                    containerClassName="c_app-modal__container"
                    bodyModalOpenClassName="modal-open"
                    wrapperClassName="c_app-modal__wrapper"
                    // onFirstModalMounted={(e) => {
                    //     console.log('onFirstModalMounted', e);
                    // }}
                    // onLastModalUnmounted={(e) => {
                    //     console.log('onLastModalUnmounted', e);
                    // }}
                />
            </div>
        </Fragment>
    );
};

export default App;
