import { Fragment, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { getProductsAsync } from '../../redux/productsSlice';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Card from '../../components/Molecules/Card/Card';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import Button from '../../components/Atoms/Button/Button';
import NoResultCard from '../../components/Organisms/NoResultCard/NoResultCard';

import './Products.scss';

const _CLASSNAME = 'c_products';
const _IMG = 'products.png';

const Products = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const products = useSelector((state) => state.products);

    // load data
    const getData = useCallback(() => {
        if (products.products.length < 1) dispatch(getProductsAsync());
    }, [dispatch, products.products.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    if (products.isLoading) {
        return (
            <DefaultLayout img={_IMG}>
                <Preloader color="white" includeOverlay={true} />
            </DefaultLayout>
        );
    }
    return (
        <DefaultLayout img={_IMG}>
            {products.products.length < 1 ? (
                <NoResultCard
                    className="mb-3"
                    title={t(`containers.products.empty-products-title`)}
                    text={t(`containers.products.empty-products-text`)}
                />
            ) : (
                <Fragment>
                    {products.products.map((product) => (
                        <Card
                            key={product.id}
                            className={`${_CLASSNAME}__item`}
                            type="product"
                            to={`/products/${product.simpleId}/`}
                            number={product.simpleId}
                            title={product.title}
                            icon="add"
                            iconColor="secondary"
                        />
                    ))}
                </Fragment>
            )}
            <div className="d-flex justify-content-center justify-content-md-start">
                <Button variant="white" label={t('containers.products.add-custom-product')} to="/products/custom/" />
            </div>
        </DefaultLayout>
    );
};

export default Products;
