import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getProductsAsync } from '../../redux/productsSlice';
import { addOrUpdateAsync } from '../../redux/cartSlice';

import Modal from '../../components/Layouts/Modal/Modal';
import Title from '../../components/Atoms/Title/Title';
import OrderForm from '../../components/Organisms/OrderForm/OrderForm';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import './ProductDetail.scss';

// const _CLASSNAME = 'c_product-detail';

const ProductDetail = ({ closeModalCustom }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams();
    const isCustomProduct = params.simpleId === 'custom';
    const cart = useSelector((state) => state.cart);
    const products = useSelector((state) => state.products);
    const product = useSelector((state) =>
        state.products.products.find((product) => product.simpleId === params.simpleId),
    );
    const productIsLoading = products.isLoading;
    const cartIsWorking = cart.isLoading || cart.isPosting;

    // load data
    const getData = useCallback(() => {
        if ((products.products.length < 1 || !product) && !isCustomProduct) dispatch(getProductsAsync());
    }, [dispatch, product, products.products.length, isCustomProduct]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    const beforeSubmit = () => {
        toast.dismiss();
    };
    const onValid = (data) => {
        const postData = {
            id: '',
            ...data,
        };
        dispatch(addOrUpdateAsync(postData))
            .then(() => {
                closeModalCustom();
                return true;
            })
            .catch(() => {
                toast.error(t('alerts.error'));
            });
    };

    if (products.isLoading) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <div className="d-flex justify-content-center">
                    <Preloader color="primary" />
                </div>
            </Modal>
        );
    }

    if (product || isCustomProduct) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <Title
                    title={isCustomProduct ? t('order.new-product-title') : product.number.toString()}
                    color="secondary"
                    className={isCustomProduct ? 'mb-2' : ''}
                    style={{ paddingRight: 30 }}
                />
                {!isCustomProduct && <Title title={product.title} className="mb-2" style={{ paddingRight: 30 }} />}
                <Wysiwyg>{isCustomProduct ? t('order.text.custom') : t(`order.text.${product.type}`)}</Wysiwyg>
                <OrderForm
                    className={`mt-4`}
                    isNew={true}
                    isCustomProduct={isCustomProduct}
                    type={isCustomProduct ? 'bag' : product.type}
                    productId={isCustomProduct ? 'custom' : product.id} // NOTE: backend assigns the id "custom" to a custom product as well, so do not change this without checking with backend
                    kg={0}
                    bags={0}
                    pallets={0}
                    silos={[]}
                    remark={''}
                    weights={isCustomProduct ? {} : product.weight}
                    isLoading={productIsLoading || cartIsWorking}
                    beforeSubmit={beforeSubmit}
                    onValid={onValid}
                />

                {cartIsWorking && <Preloader color="primary" overlayColor="white" includeOverlay={true} />}
            </Modal>
        );
    }
    return <Redirect to="/products/" />;
};

export default ProductDetail;

ProductDetail.propTypes = {
    closeModalCustom: PropTypes.func.isRequired,
};
ProductDetail.defaultProps = {};
