import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getOrdersAsync } from '../../redux/ordersSlice';

import Modal from '../../components/Layouts/Modal/Modal';
import OrderOverview from '../../components/Organisms/OrderOverview/OrderOverview';
import Button from '../../components/Atoms/Button/Button';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import './OrderDetail.scss';

const _CLASSNAME = 'c_order-detail';

const OrderDetail = ({ closeModalCustom }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams();
    const orders = useSelector((state) => state.orders);
    const order = useSelector((state) => state.orders.orders.find((order) => order.simpleId === params.simpleId));

    // load data
    const getData = useCallback(() => {
        if (orders.orders.length < 1 || !order) dispatch(getOrdersAsync());
    }, [dispatch, order, orders.orders.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    if (orders.isLoading) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <div className="d-flex justify-content-center">
                    <Preloader color="primary" />
                </div>
            </Modal>
        );
    }

    if (order) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <OrderOverview
                    title={order.title}
                    subTitle={order.date}
                    delivery={order.delivery}
                    deliveryType={order.deliveryType}
                    address={order.addressFormatted}
                    remark={order.remark}
                    cart={order.cart}
                />
                <div className={`d-flex justify-content-center ${_CLASSNAME}__submit-wrapper`}>
                    <Button label={t('containers.order-detail.back')} variant="secondary" to={'/orders/'} />
                </div>
            </Modal>
        );
    }
    return <Redirect to="/orders/" />;
};

export default OrderDetail;

OrderDetail.propTypes = {
    closeModalCustom: PropTypes.func.isRequired,
};
OrderDetail.defaultProps = {};
