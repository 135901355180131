// https:www.freecodecamp.org/news/redux-for-beginners-the-brain-friendly-guide-to-redux/
// immer: https://redux-toolkit.js.org/usage/immer-reducers

import { configureStore } from '@reduxjs/toolkit';
import orderReducer from './ordersSlice';
import userReducer from './userSlice';
import productsReducer from './productsSlice';
import cartReducer from './cartSlice';

export default configureStore({
    reducer: {
        user: userReducer,
        orders: orderReducer,
        products: productsReducer,
        cart: cartReducer,
    },
});
