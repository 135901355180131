import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getCartAsync, addOrUpdateAsync, removeFromCartAsync } from '../../redux/cartSlice';

import Modal from '../../components/Layouts/Modal/Modal';
import Title from '../../components/Atoms/Title/Title';
import OrderForm from '../../components/Organisms/OrderForm/OrderForm';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';
import Button from '../../components/Atoms/Button/Button';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import './CartDetail.scss';

const _CLASSNAME = 'c_cart-detail';

const CartDetail = ({ closeModalCustom }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams();
    const cart = useSelector((state) => state.cart);
    const isPosting = useSelector((state) => state.cart.isPosting);
    const isLoading = useSelector((state) => state.cart.isLoading);
    const cartItem = useSelector((state) => state.cart.cart.find((cartItem) => cartItem.id === params.id));
    const [showConfirm, setShowConfirm] = useState(false);

    // load data
    const getData = useCallback(() => {
        if (cart.cart.length < 1) dispatch(getCartAsync());
    }, [dispatch, cart.cart.length]);
    useEffect(() => {
        getData();
        return () => {};
    }, [getData]);

    const beforeSubmit = () => {
        toast.dismiss();
    };
    const onValid = (data) => {
        dispatch(
            addOrUpdateAsync({
                id: cartItem.id,
                ...data,
            }),
        )
            .then((d) => {
                toast.success(t('alerts.success-cart-updated'));
                closeModalCustom();
                return true;
            })
            .catch(() => {
                toast.error(t('alerts.error'));
            });
    };
    const toggleDeleteConfirm = (bool = true) => {
        setShowConfirm(bool);
    };
    const handleDeleteFromCart = () => {
        dispatch(
            removeFromCartAsync({
                id: params.id,
            }),
        )
            .then(() => {
                closeModalCustom();
                return true;
            })
            .catch(() => {
                toast.error(t('alerts.error'));
            });
    };

    if (isLoading) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <div className="d-flex justify-content-center">
                    <Preloader color="primary" />
                </div>
            </Modal>
        );
    }

    if (cartItem) {
        const quantity = cartItem.quantity;
        const product = cartItem.product;
        const isCustomProduct = product.id === 'custom';
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <Wysiwyg>
                    <Title
                        title={product.number.toString()}
                        color="secondary"
                        tag="span"
                        style={{ paddingRight: 30 }}
                    />
                    <Title title={product.title} style={{ paddingRight: 30 }} />
                    {t(`order.text.${product.type}`)}
                </Wysiwyg>
                <OrderForm
                    className={`mt-4`}
                    isCustomProduct={isCustomProduct}
                    isNew={false}
                    productId={product.id} // this is "custom" of it's a custom product
                    type={product.type}
                    kg={quantity.kg}
                    bags={quantity.bags}
                    bigbags={quantity.bigbags}
                    pieces={quantity.pieces}
                    pallets={quantity.pallets}
                    silos={quantity.silos}
                    remark={cartItem.remark}
                    weights={product.weight}
                    isLoading={isLoading || isPosting}
                    beforeSubmit={beforeSubmit}
                    onValid={onValid}
                    productName={isCustomProduct ? product.title : ''}
                    productCode={isCustomProduct ? product.number : ''}
                />
                <div className={`d-flex justify-content-center`}>
                    <Button
                        label={t('order.delete-from-cart')}
                        variant="primary"
                        inline={true}
                        icon="delete"
                        className={`${_CLASSNAME}__delete`}
                        onClick={() => {
                            toggleDeleteConfirm(true);
                        }}
                        disabled={isLoading || isPosting}
                        size="small"
                    />
                </div>
                {showConfirm && (
                    <div
                        className={`${_CLASSNAME}__confirm-window d-flex justify-content-center align-items-center p-4`}
                    >
                        <Wysiwyg centered>
                            <Title>{t('containers.cart-detail.confirm-delete-title')}</Title>
                            <p>{t('containers.cart-detail.confirm-delete-text')}</p>
                            <div className="d-flex align-items-center justify-content-center">
                                <Button
                                    label={t('containers.cart-detail.confirm-delete-confirm')}
                                    variant="secondary"
                                    onClick={handleDeleteFromCart}
                                    disabled={isLoading || isPosting}
                                />
                                <Button
                                    label={t('containers.cart-detail.confirm-delete-decline')}
                                    variant="primary"
                                    inline={true}
                                    onClick={() => {
                                        toggleDeleteConfirm(false);
                                    }}
                                    disabled={isLoading || isPosting}
                                    className="ml-4"
                                    size="small"
                                />
                            </div>
                        </Wysiwyg>
                    </div>
                )}

                {(isLoading || isPosting) && <Preloader color="primary" overlayColor="white" includeOverlay={true} />}
            </Modal>
        );
    }
    return <Redirect to="/cart/" />;
};

export default CartDetail;

CartDetail.propTypes = {
    closeModalCustom: PropTypes.func.isRequired,
};
CartDetail.defaultProps = {};
