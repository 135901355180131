import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Modal from '../../components/Layouts/Modal/Modal';
import CheckoutForm from '../../components/Organisms/CheckoutForm/CheckoutForm';

import './CartCheckout.scss';

// const _CLASSNAME = 'c_cart-checkout';

const CartCheckout = ({ closeModalCustom }) => {
    const cartItems = useSelector((state) => state.cart.cart);
    if (cartItems.length) {
        return (
            <Modal closeModalCustom={closeModalCustom}>
                <CheckoutForm />
            </Modal>
        );
    }
    return <Redirect to="/cart/" />;
};

export default CartCheckout;

CartCheckout.propTypes = {
    closeModalCustom: PropTypes.func.isRequired,
};
CartCheckout.defaultProps = {};
