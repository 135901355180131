import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';

import DefaultLayout from '../../components/Layouts/Default/Default';
import Title from '../../components/Atoms/Title/Title';
import Button from '../../components/Atoms/Button/Button';
import Input from '../../components/Atoms/Input/Input';
import Checkbox from '../../components/Atoms/Checkbox/Checkbox';
import AlertText from '../../components/Atoms/AlertText/AlertText';
import Preloader from '../../components/Atoms/Preloader/Preloader';
import ContactCard from '../../components/Organisms/ContactCard/ContactCard';

import { loginAsync, forgotPasswordAsync } from '../../redux/userSlice';

import './Login.scss';

const _CLASSNAME = 'c_login';

const Login = () => {
    let location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector((state) => state.user);
    const isLoading = user.isLoading || user.isRefreshingToken;

    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const getRedirectUrl = () => {
        if (
            location.state &&
            location.state.referrer &&
            location.state.referrer !== '' &&
            location.state.referrer !== '/login/'
        )
            return location.state.referrer;
        return '/';
    };

    // redirect when already logged in!
    useEffect(() => {
        if (user.loggedIn) history.push(getRedirectUrl());
        // eslint-disable-next-line
    }, []);

    // reset when switching from views
    useEffect(() => {
        setErrors({});
        setUserName('');
    }, [showForgotPassword]);

    const handeFormSubmit = async (e) => {
        e.preventDefault();
        if (showForgotPassword) {
            return dispatch(
                forgotPasswordAsync({
                    username,
                }),
            )
                .then((res) => {
                    if (res.payload.success) {
                        toast.success(t('containers.login.success-forgot'), { autoClose: false });
                        setShowForgotPassword(false);
                    } else {
                        setErrors(res.payload.errors);
                    }
                })
                .catch(() => {
                    toast.error(t('alerts.error'));
                });
        }

        return dispatch(
            loginAsync({
                username,
                password,
                remember,
            }),
        )
            .then((res) => {
                if (res.payload.success) {
                    history.push(getRedirectUrl());
                } else {
                    setErrors(res.payload.errors);
                }
            })
            .catch(() => {
                toast.error(t('alerts.error'));
            });
    };

    return (
        <DefaultLayout img={'login.png'}>
            <div className="mb-8">
                <form className={_CLASSNAME} onSubmit={handeFormSubmit} noValidate>
                    {showForgotPassword ? (
                        <div className={`${_CLASSNAME}__inner`}>
                            <Title color="white" className="mb-3">
                                {t('containers.login.forgot-password')}
                            </Title>
                            <Input
                                type="email"
                                name={`username`}
                                id={`username`}
                                value={username}
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                                label={t('containers.login.username')}
                                className={`${_CLASSNAME}__input mb-2`}
                                error={errors.username && errors.username !== ''}
                                errorMessage={t(`containers.login.errors.forgot.username.${errors.username}`)}
                                variant="inverted"
                                disabled={isLoading}
                            />
                            {errors.general && errors.general !== '' && (
                                <div className={`${_CLASSNAME}__error`}>
                                    <AlertText
                                        icon="error"
                                        text={t(`containers.login.errors.forgot.general.${errors.general}`)}
                                        color="white"
                                    />
                                </div>
                            )}
                            <div className="d-flex mt-4">
                                <Button
                                    label={t('containers.login.send')}
                                    type="submit"
                                    variant="white"
                                    disabled={isLoading}
                                />
                                <Button
                                    className="ml-4"
                                    inline={true}
                                    label={t('general.back')}
                                    onClick={() => {
                                        setShowForgotPassword(false);
                                    }}
                                    variant="white"
                                    disabled={isLoading}
                                    size="small"
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={`${_CLASSNAME}__inner`}>
                            <Title color="white" className="mb-3">
                                {t('containers.login.title')}
                            </Title>
                            <Input
                                type="email"
                                name={`username`}
                                id={`username`}
                                value={username}
                                onChange={(e) => {
                                    setUserName(e.target.value);
                                }}
                                label={t('containers.login.username')}
                                className={`${_CLASSNAME}__input mb-2`}
                                error={errors.username && errors.username !== ''}
                                errorMessage={t(`containers.login.errors.login.username.${errors.username}`)}
                                variant="inverted"
                                disabled={isLoading}
                            />
                            <Input
                                type="password"
                                name={`password`}
                                id={`password`}
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                label={t('containers.login.password')}
                                className={`${_CLASSNAME}__input mb-4`}
                                error={errors.password && errors.password !== ''}
                                errorMessage={t(`containers.login.errors.login.password.${errors.password}`)}
                                variant="inverted"
                                disabled={isLoading}
                            />
                            <Checkbox
                                name={`remember`}
                                id={`remember`}
                                value={'1'}
                                isChecked={remember}
                                onChange={(e) => {
                                    setRemember(e.target.checked);
                                }}
                                label={t('containers.login.stay-signed-in')}
                                className={`${_CLASSNAME}__checkbox mb-2`}
                                variant="inverted"
                            />
                            {errors.general && errors.general !== '' && (
                                <div className={`${_CLASSNAME}__error`}>
                                    <AlertText
                                        icon="error"
                                        text={t(`containers.login.errors.login.general.${errors.general}`)}
                                        color="white"
                                    />
                                </div>
                            )}
                            <div className="d-flex mt-4">
                                <Button
                                    label={t('containers.login.submit')}
                                    type="submit"
                                    variant="white"
                                    disabled={isLoading}
                                />
                                <Button
                                    className="ml-4"
                                    inline={true}
                                    label={t('containers.login.forgot-password')}
                                    onClick={() => {
                                        setShowForgotPassword(true);
                                    }}
                                    variant="white"
                                    disabled={isLoading}
                                    size="small"
                                />
                            </div>
                        </div>
                    )}
                    <Preloader includeOverlay={true} active={isLoading} />
                </form>
            </div>
            <ContactCard />
        </DefaultLayout>
    );
};

export default Login;
