import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ReactComponent as SVGLogo } from './Logo.svg';

import './Logo.scss';

const _CLASSNAME = 'c_logo';

const Logo = ({ width, className, to }) => {
    const classList = [_CLASSNAME, 'd-block', className].filter((c) => c !== '').join(' ');
    if (to !== '')
        return (
            <NavLink to={to} className={classList} style={{ width }} alt="Schils">
                <SVGLogo />
            </NavLink>
        );
    return (
        <figure className={classList} style={{ width }}>
            <SVGLogo />
        </figure>
    );
};

export default Logo;

Logo.propTypes = {
    width: PropTypes.number,
    className: PropTypes.string,
    to: PropTypes.string,
};
Logo.defaultProps = {
    width: 130,
    className: '',
    to: '',
};
