import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HomeLayout from '../../components/Layouts/Home/Home';
import Title from '../../components/Atoms/Title/Title';
import Card from '../../components/Molecules/Card/Card';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';
import ContactCard from '../../components/Organisms/ContactCard/ContactCard';
import AutocompleteSelect from '../../components/Atoms/AutocompleteSelect/AutocompleteSelect';
import Preloader from '../../components/Atoms/Preloader/Preloader';

import { switchClientAsync } from '../../redux/userSlice';

import './Dashboard.scss';

const _CLASSNAME = 'c_dashboard';

const DashBoard = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const { isSwitchingClient } = user;
    const { t } = useTranslation();

    const handleSwitchClient = ({ value }) => {
        dispatch(switchClientAsync({ id: value }));
    };

    const isReseller = user.user.role === 'reseller';
    let resellerOptions = [];
    let resellerValue = {};
    if (isReseller) {
        resellerOptions = user.user.clients.map((item) => ({ value: item.id, label: item.companyName }));
        const activeClient = user.user.clients.filter((item) => item.active)[0] || {};
        resellerValue = {
            value: activeClient.id || '',
            label: activeClient.companyName || '',
        };
    }

    return (
        <HomeLayout img1="dashboard-1.png" img2="dashboard-2.png" contactBlock={isReseller ? null : <ContactCard />}>
            <div className={_CLASSNAME}>
                <Card className={`${_CLASSNAME}__card`}>
                    <Wysiwyg>
                        <Title>
                            {t('containers.dashboard.welcome')} <b>{user.user.name || '...'}</b>
                        </Title>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: t(`containers.dashboard.text-${isReseller ? 'reseller' : 'client'}`),
                            }}
                        ></div>
                    </Wysiwyg>
                    {isReseller && (
                        <div className={`${_CLASSNAME}__selector`}>
                            <AutocompleteSelect
                                id="reseller-client-selector"
                                options={resellerOptions}
                                value={resellerValue}
                                onChange={handleSwitchClient}
                                noOptionsMessage={t('general.no-results')}
                                placeholder={`${t('containers.dashboard.selectclient')}...`}
                                label={t('containers.dashboard.selectclient')}
                                disabled={isSwitchingClient}
                            />
                            <Preloader
                                includeOverlay={true}
                                active={isSwitchingClient}
                                overlayColor="white"
                                color="primary"
                            />
                        </div>
                    )}
                </Card>
            </div>
        </HomeLayout>
    );
};

export default DashBoard;
