import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';

import './Button.scss';

const _CLASSNAME = 'c_button';

const Button = ({ to, label, className, variant, size, onClick, disabled, type, inline, icon, iconSize }) => {
    const Tag = to !== '' ? Link : 'button';

    const handleOnClick = (e) => {
        if (onClick) onClick(e);
    };
    return (
        <Tag
            to={to}
            className={[
                _CLASSNAME,
                `${inline ? `${_CLASSNAME}--inline` : ''}`,
                `${_CLASSNAME}--size-${size}`,
                `${_CLASSNAME}--${variant}`,
                className,
            ]
                .filter((i) => i !== '')
                .join(' ')}
            onClick={handleOnClick}
            disabled={disabled}
            type={type}
        >
            {icon !== '' && <Icon icon={icon} size={iconSize} className={`${_CLASSNAME}__icon`} />}
            {label}
        </Tag>
    );
};

export default Button;

Button.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'white']),
    size: PropTypes.oneOf(['default', 'small']),
    inline: PropTypes.bool,
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    type: PropTypes.string,
};
Button.defaultProps = {
    to: '',
    label: '',
    className: '',
    variant: 'primary',
    size: 'default',
    inline: false,
    icon: '',
    iconSize: 1.3,
    disabled: false,
    type: 'button',
};
