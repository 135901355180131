import PropTypes from 'prop-types';

import './Radiobutton.scss';

import AlertText from '../AlertText/AlertText';

const _CLASSNAME = 'c_radiobutton';

const Radiobutton = ({
    label,
    isChecked,
    name,
    id,
    value,
    onChange,
    className,
    error,
    errorMessage,
    variant,
    disabled,
}) => {
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };
    return (
        <div className={[_CLASSNAME, className, `${_CLASSNAME}--${variant}`].filter((c) => c !== '').join(' ')}>
            <label
                htmlFor={id}
                className={[`${_CLASSNAME}__label`, `${error ? 's_error' : ''}`, 'd-flex align-items-center']
                    .filter((c) => c !== '')
                    .join(' ')}
            >
                <input
                    className={`${_CLASSNAME}__input`}
                    type="radio"
                    name={name}
                    id={id}
                    value={value}
                    checked={isChecked}
                    onChange={handleChange}
                    disabled={disabled}
                />
                <span
                    className={[
                        `${_CLASSNAME}__checkbox`,
                        `${error ? 's_error' : ''}`,
                        'mr-2 d-flex align-items-center justify-content-center',
                    ]
                        .filter((c) => c !== '')
                        .join(' ')}
                >
                    {isChecked && <span className={`${_CLASSNAME}__icon ${error ? 's_error' : ''}`} />}
                </span>
                <span
                    className={[`${_CLASSNAME}__label-inner`, `${error ? 's_error' : ''}`]
                        .filter((c) => c !== '')
                        .join(' ')}
                >
                    {label}
                </span>
            </label>
            {error && errorMessage !== '' && (
                <span className={`${_CLASSNAME}__error-msg`}>
                    <AlertText icon="error" text={errorMessage} color={variant === 'inverted' ? 'white' : 'primary'} />
                </span>
            )}
        </div>
    );
};

export default Radiobutton;

Radiobutton.propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'inverted']),
};
Radiobutton.defaultProps = {
    className: '',
    label: '',
    value: '',
    name: '',
    id: 'id',
    disabled: false,
    error: false,
    errorMessage: '',
    variant: 'default',
};
