import PropTypes from 'prop-types';
import { MdHistory, MdFace, MdInbox, MdDelete, MdError, MdArrowDropDown } from 'react-icons/md';
import { TiPlus, TiTick, TiArrowRightThick, TiTimes, TiMinus } from 'react-icons/ti';
import { ImPlus, ImPhone } from 'react-icons/im';

const icons = {
    history: MdHistory,
    face: MdFace,
    add: TiPlus,
    next: TiArrowRightThick,
    close: TiTimes,
    inbox: MdInbox,
    delete: MdDelete,
    error: MdError,
    check: TiTick,
    dropdown: MdArrowDropDown,
    call: ImPhone,
    remove: TiMinus,
    plus: ImPlus,
};

const _CLASSNAME = 'c_icon';
const _BASE_FONT_SIZE = 14;

const Icon = ({ icon, size, className }) => {
    if (icons[icon]) {
        const Tag = icons[icon];
        return (
            <Tag
                className={[_CLASSNAME, className].filter((c) => c !== '').join(' ')}
                size={`${size * _BASE_FONT_SIZE}px`}
            />
        );
    }
    return null;
};

export default Icon;

Icon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};
Icon.defaultProps = {
    icon: '',
    size: 1,
    className: '',
};
