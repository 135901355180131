import Cookies from 'js-cookie';

const _PREFIX = 'schils_';

const cookieAPI = {
    get(key) {
        const string = Cookies.getJSON(`${_PREFIX}_${key}`);
        return string || false;
    },
    save(key, data, expires = 60) {
        Cookies.set(`${_PREFIX}_${key}`, data, { expires });
        return true;
    },
    delete(key) {
        Cookies.remove(`${_PREFIX}_${key}`);
        return false;
    },
};

export default cookieAPI;
