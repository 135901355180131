import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Logo from '../../components/Atoms/Logo/Logo';
import Title from '../../components/Atoms/Title/Title';
import Wysiwyg from '../../components/Atoms/Wysiwyg/Wysiwyg';

import './OfflineScreen.scss';

const _CLASSNAME = 'c_offline-screen';

const OfflineScreen = ({ className }) => {
    const { t } = useTranslation();
    return (
        <div className={`${_CLASSNAME} ${className} d-flex justify-content-center align-items-center p-4`}>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <Logo className={`${_CLASSNAME}__logo`} />
                <Title title={t('general.offline-title')} color="white" className="mb-3" />
                <Wysiwyg color="white" centered>
                    <p>{t('general.offline-text')}</p>
                </Wysiwyg>
            </div>
        </div>
    );
};

export default OfflineScreen;

OfflineScreen.propTypes = {
    className: PropTypes.string,
};
OfflineScreen.defaultProps = {
    className: '',
};
