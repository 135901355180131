import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import LanguageSwitch from '../../Molecules/LanguageSwitch/LanguageSwitch';
import Icon from '../../Atoms/Icon/Icon';
import Logo from '../../Atoms/Logo/Logo';

import './Header.scss';

const _CLASSNAME = 'c_header';

const Menu = () => {
    const { t } = useTranslation();
    return (
        <div className={`${_CLASSNAME}__buttons`}>
            <NavLink to="/profile/" className={`${_CLASSNAME}__button`} title={t('menu.profile')}>
                <Icon icon="face" className={`${_CLASSNAME}__button-icon`} size={1.5} />
                <span className={`${_CLASSNAME}__button-label`}>{t('menu.profile')}</span>
            </NavLink>
            <NavLink to="/orders/" className={`${_CLASSNAME}__button`} title={t('menu.orders')}>
                <Icon icon="history" className={`${_CLASSNAME}__button-icon`} size={1.6} />
                <span className={`${_CLASSNAME}__button-label`}>{t('menu.orders')}</span>
            </NavLink>
        </div>
    );
};

const Actions = () => {
    const { t } = useTranslation();
    let hideActions = useRouteMatch('/success/:simpleId/');
    const cartCount = useSelector((state) => state.cart.count);

    // don't render the actions if on success page!
    if (hideActions) return null;

    return (
        <div className={`${_CLASSNAME}__actions`}>
            <NavLink
                to="/products/"
                className={`${_CLASSNAME}__button ${_CLASSNAME}__button--add`}
                title={t('menu.products')}
            >
                <Icon icon="plus" className={`${_CLASSNAME}__button-icon`} />
            </NavLink>
            <NavLink to="/cart/" className={`${_CLASSNAME}__button ${_CLASSNAME}__button--cart`} title={t('menu.cart')}>
                <Icon icon="inbox" className={`${_CLASSNAME}__button-icon`} size={1.8} />
                <span className={`${_CLASSNAME}__button-label`}>{t('menu.cart')}</span>
                {cartCount > 0 && <span className={`${_CLASSNAME}__cart-counter`}>{cartCount}</span>}
            </NavLink>
        </div>
    );
};

const Header = ({ className, isLoggedIn }) => {
    return (
        <header className={`${_CLASSNAME} ${className}`}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className={`${_CLASSNAME}__inner d-flex justify-content-between align-items-end`}>
                            <Logo to="/" className={`${_CLASSNAME}__logo`} title="Schils" />
                            <div className={`${_CLASSNAME}__right`}>
                                {!isLoggedIn ? (
                                    <LanguageSwitch type="button" />
                                ) : (
                                    <div className="d-flex">
                                        <Actions />
                                        <Menu />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;

Header.propTypes = {
    className: PropTypes.string,
    isLoggedIn: PropTypes.bool,
};
Header.defaultProps = {
    className: '',
    isLoggedIn: false,
};
